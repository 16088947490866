<template>
  <div class="overlaybg" :class="{ 'd-none': !isOverlayVisible }" id="overlaybg" @click="toggleOverlay()"></div>
  <div id="loader" style="display: none;">
    <div class="spinner"></div>
  </div>
  <div>
    <!-- Horizontal Calendar Section start -->
    <HorizonCalendar @datePicked="changeDate($event)" ref="horizonCalendarAction" />
    <!-- Horizontal Calendar Section End -->
    <section class="bar-chart-main position-relative ">

      <a href="#" class="customer-add-btn ms-auto float-right" v-on:click="refreshBarGraphData()"><i
          class="fa fa-refresh" aria-hidden="true"></i>

      </a>
      <div class="dropdown show float-right mr-3">
        <a class="btn btn-secondary dropdown-toggle bgcolor-yellow" href="#" role="button" id="dropdownMenuLink"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Inactive Technician
        </a>

        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <a class="dropdown-item" href="#">Alison</a>
          <a class="dropdown-item" href="#">Jhon D</a>
          <a class="dropdown-item" href="#">Jr. Robert</a>
        </div>
      </div>
      <div class="overflow-x">
        <div class="bar-timings">
          <div v-for="hour in hours" :key="hour" class="hour-bar" :id="hour">
            <div class="time">
              <span> {{ formatHourAm_Pm(hour) }} </span>
            </div>
            <div class="linings">
              <div v-for="minute in [15, 30, 45]" :key="minute" class="min-line">


                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="1483.000000pt" height="1708.000000pt"
                  viewBox="0 0 1483.000000 1708.000000" preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,1708.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M10065 14339 c-2612 -1506 -5943 -3426 -7402 -4266 -1970 -1135
                      -2648 -1531 -2640 -1539 13 -12 14759 -8512 14783 -8521 8 -3 -22 46 -67 108
                      -121 169 -411 609 -536 814 -1210 1981 -1923 4195 -2098 6515 -52 691 -52
                      1483 1 2180 159 2144 785 4212 1837 6075 215 381 530 878 771 1220 93 131 110
                      156 104 154 -2 0 -2140 -1233 -4753 -2740z" />
                  </g>
                </svg>

                <span>{{ minute }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="bar-chart-listing position-relative">

          <div class="appointment-list" style="margin-bottom: 20px;" v-for="(tech, index) in technician"
            :key="tech.technician_id">
            <div class="user-img" @click="getTechIdAddTechnician(tech.technician_id)">
              <img :src="tech.tech_imageUrl" alt="Technician">
            </div>
            <span class="numbers green-bg">115’ <span></span></span>
            <div class="collapse data-collapse" :id="'userData' + tech.technician_id">
              <div class="label-on">
                <p class="">{{ tech.fname }} {{ tech.lname }}</p>

                <div class="d-flex justify-content-around test">
                  <div class="appt-no-display color-apt">Appt {{ totalAppointmentRecord[index].totalAppointment }}</div>
                  <div class="ml-0 color-apt">Earned {{ totalAppointmentRecord[index].earningTotal }} $</div>
                </div>
                <a href="#" class="close" @click="hideMod(tech.id)" id="glassAnimals">
                  <svg version="1.0" id="katman_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1436 1054"
                    style="enable-background:new 0 0 1436 1054;" xml:space="preserve">
                    <path
                      d="M718.5,453.8l224-224.3c20.4-20.4,53.3-20.4,73.6,0c20.4,20.4,20.4,53.3,0,73.6l-224,224.6l224,224
                      c20.4,20.4,20.4,53.3,0,73.6c-20.4,20.4-53.3,20.4-73.6,0l-224-224l-224.6,224c-20.4,20.4-53.3,20.4-73.6,0
                      c-20.4-20.4-20.4-53.3,0-73.6l224-224L420.4,303.2c-20.5-20.4-20.5-53.3-0.1-73.6s53.3-20.4,73.6,0l224.6,224V453.8z" />
                  </svg>
                </a>
              </div>
            </div>
            <div class="bars">
              <div v-for="(hour, index) in hours" :key="hour" class="hour-bar-loop">
                <div class="time-loop">
                  <span class="popopennewcustomer"
                    @click="showModel(tech.technician_id, formatHourMinute(hour, '00')); technicianIdselected(tech.technician_id); techNameGet(tech.fname, tech.lname)"
                    v-on:click="getCoordinateClicked($event, tech.technician_id, formatHourMinute(hour, '00'));">
                    {{ formatHour(hour) }}
                  </span>
                </div>
                <div class="linings-one" ref="timeElements">
                  <div v-for="minute in [15, 30, 45]" :key="minute" class="min-line-loop">
                    <p @click="showModel(tech.technician_id, formatHourMinute(hour, minute)); technicianIdselected(tech.technician_id); techNameGet(tech.fname, tech.lname)"
                      v-on:click="getCoordinateClicked($event, tech.technician_id, formatHourMinute(hour, minute));">
                      {{ minute }}</p>
                  </div>
                </div>
              </div>
              <div class="bars-rel ml-2 accept-btn-event" @click="toggleCollapse(tech.technician_id)" id="userD">
                <template v-for=" (bargraphData, indexes) in latestBarGraphData" :key="bargraphData">
                  <template v-if="bargraphData.technicianID == tech.technician_id">
                    <span v-for="(graphData, index) in bargraphData.data" :key="graphData"
                      @click="clickeventAction($event, bargraphData, tech.technician_id, index)"
                      @keyup="pickupActionValueKeyUp">
                      <div v-html="graphData"></div>
                    </span>
                  </template>
                </template>
              </div>
              <ModelCards @updateTotalPrice="updateTotalAppointmentPrice" @addOnServiceData="handleAddOnServices"
                :appointementList="appointementList" :totalAppointmentPrice="totalAppointmentPrice"
                :remainingAmount="remainingAmount" :appointementId="selectedAppointment"
                :showFirstModal="showFirstModal" :technicianId="storedTechnicianId" @closeFirstModal="closeFirstModal"
                :customerDetails="customerDetail" @refreshGraphs="refreshGraph" @fetch_appointment="fetch_appointment"
                :appointmentTimes="appointmentTime">
              </ModelCards>
              <div class="booking_holding" id="bookingNewCustomer">
                <div id="booking-holding-one">
                  <div class="booking-type text-center">
                    <p class="heading-20-white" id="timeData">{{ currentTechnician.time }}
                    </p>
                    <a href="#" class="float-right" @click="hideMod(currentTechnician.id)" id="glassAnimals">
                      <svg data-v-e73c3a1a="" version="1.0" id="katman_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1436 1054"
                        xml:space="preserve">
                        <path data-v-e73c3a1a=""
                          d="M718.5,453.8l224-224.3c20.4-20.4,53.3-20.4,73.6,0c20.4,20.4,20.4,53.3,0,73.6l-224,224.6l224,224
                      c20.4,20.4,20.4,53.3,0,73.6c-20.4,20.4-53.3,20.4-73.6,0l-224-224l-224.6,224c-20.4,20.4-53.3,20.4-73.6,0
                      c-20.4-20.4-20.4-53.3,0-73.6l224-224L420.4,303.2c-20.5-20.4-20.5-53.3-0.1-73.6s53.3-20.4,73.6,0l224.6,224V453.8z">
                        </path>
                      </svg>
                    </a>
                  </div>
                  <div class="booking-body-end">
                    <form v-on:submit.prevent="save_temp_service">
                      <div v-if="customerNameSelected == '' ? true : false" class="form-group search-group mb-1">
                        <label class="input-label">Add Customer</label>
                        <input type="text" class="form-control search_customer_mobile" placeholder="Search by phone"
                          v-model="searchValue" @keyup="getData">
                        <span class="dummy-img">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15.201" height="16.859"
                            viewBox="0 0 15.201 16.859">
                            <g id="Group_1632" data-name="Group 1632" transform="translate(4923.887 6021.4)">
                              <g id="Group_1631" data-name="Group 1631">
                                <path id="Path_1413" data-name="Path 1413"
                                  d="M-4916.286-6012.585a4.412,4.412,0,0,1-4.407-4.407,4.413,4.413,0,0,1,4.407-4.408,4.413,4.413,0,0,1,4.407,4.408A4.412,4.412,0,0,1-4916.286-6012.585Zm0-8.022a3.619,3.619,0,0,0-3.616,3.615,3.62,3.62,0,0,0,3.616,3.615,3.62,3.62,0,0,0,3.616-3.615A3.619,3.619,0,0,0-4916.286-6020.607Z"
                                  fill="#d5a948" />
                                <path id="Path_1414" data-name="Path 1414"
                                  d="M-4923.887-6004.541l.038-2.617a4.866,4.866,0,0,1,4.861-4.86h.188a3.842,3.842,0,0,0,2.532,1,3.605,3.605,0,0,0,2.392-.9l.16-.089.171-.011a4.867,4.867,0,0,1,4.859,4.862v2.615Zm14.376-.792.035-1.825a4.083,4.083,0,0,0-4.02-4.067,4.28,4.28,0,0,1-2.772,1,4.4,4.4,0,0,1-2.8-1,4.084,4.084,0,0,0-3.992,4.069v1.787Z"
                                  fill="#d5a948" />
                              </g>
                            </g>
                          </svg>

                        </span>
                        <button class="search">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <path id="Path_465" data-name="Path 465"
                              d="M-6225.9-7073.878a.808.808,0,0,1-.574-.25c-.8-.787-1.6-1.581-2.38-2.388a.757.757,0,0,1-.235-.516c.064-.388-.156-.578-.39-.778l-.028-.025-.042.022a5.687,5.687,0,0,1-3.775,1.423,5.836,5.836,0,0,1-4.49-2.112,5.777,5.777,0,0,1,.487-7.788,5.587,5.587,0,0,1,3.948-1.589,5.815,5.815,0,0,1,3.729,1.349,5.749,5.749,0,0,1,2.015,3.921,5.91,5.91,0,0,1-1.457,4.355l.1.088a2.151,2.151,0,0,0,.255.206.489.489,0,0,0,.236.084,1.447,1.447,0,0,1,.2-.018.786.786,0,0,1,.563.241c.806.8,1.61,1.6,2.4,2.406a.792.792,0,0,1,.238.574.8.8,0,0,1-.256.565A.777.777,0,0,1-6225.9-7073.878Zm-7.473-13.047a4.772,4.772,0,0,0-4.773,4.748,4.826,4.826,0,0,0,1.392,3.426,4.751,4.751,0,0,0,3.4,1.407,4.794,4.794,0,0,0,4.787-4.776,4.788,4.788,0,0,0-1.4-3.405,4.765,4.765,0,0,0-3.4-1.4l-.008-.2Z"
                              transform="translate(6239.094 7087.878)" fill="#97bfb2"></path>
                          </svg>
                        </button>
                        <button id="icon_changeO" class="icon_change" v-if="removeIcoButton"
                          @click="removeCustomerSearch()">
                          <svg data-v-e73c3a1a="" version="1.0" id="katman_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1436 1054"
                            xml:space="preserve">
                            <path data-v-e73c3a1a=""
                              d="M718.5,453.8l224-224.3c20.4-20.4,53.3-20.4,73.6,0c20.4,20.4,20.4,53.3,0,73.6l-224,224.6l224,224
                          c20.4,20.4,20.4,53.3,0,73.6c-20.4,20.4-53.3,20.4-73.6,0l-224-224l-224.6,224c-20.4,20.4-53.3,20.4-73.6,0
                          c-20.4-20.4-20.4-53.3,0-73.6l224-224L420.4,303.2c-20.5-20.4-20.5-53.3-0.1-73.6s53.3-20.4,73.6,0l224.6,224V453.8z">
                            </path>
                          </svg>
                        </button>
                      </div>
                      <div class="some_o">
                        <ul class="bg_white" id="bg_white_one">
                          <li class="bg-white px-3 py-1" v-for="cust in customer" :key="cust.id"
                            @click="selectCustomer(cust.id)" :style="{ border: cust ? '1px solid #eaeaea;' : '' }">
                            <a class="first-ch" href="javascript:void(0);" :class="{ 'selected': isSelected(cust.id) }">
                              <label class="mb-0">
                                <div class="customer-name-new">
                                  <h4 class="heading-20-white">Customer</h4>
                                  <p>
                                    <span class="dummy-img mb-0">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="15.201" height="16.859"
                                        viewBox="0 0 15.201 16.859">
                                        <g id="Group_1632" data-name="Group 1632"
                                          transform="translate(4923.887 6021.4)">
                                          <g id="Group_1631" data-name="Group 1631">
                                            <path id="Path_1413" data-name="Path 1413"
                                              d="M-4916.286-6012.585a4.412,4.412,0,0,1-4.407-4.407,4.413,4.413,0,0,1,4.407-4.408,4.413,4.413,0,0,1,4.407,4.408A4.412,4.412,0,0,1-4916.286-6012.585Zm0-8.022a3.619,3.619,0,0,0-3.616,3.615,3.62,3.62,0,0,0,3.616,3.615,3.62,3.62,0,0,0,3.616-3.615A3.619,3.619,0,0,0-4916.286-6020.607Z"
                                              fill="#d5a948" />
                                            <path id="Path_1414" data-name="Path 1414"
                                              d="M-4923.887-6004.541l.038-2.617a4.866,4.866,0,0,1,4.861-4.86h.188a3.842,3.842,0,0,0,2.532,1,3.605,3.605,0,0,0,2.392-.9l.16-.089.171-.011a4.867,4.867,0,0,1,4.859,4.862v2.615Zm14.376-.792.035-1.825a4.083,4.083,0,0,0-4.02-4.067,4.28,4.28,0,0,1-2.772,1,4.4,4.4,0,0,1-2.8-1,4.084,4.084,0,0,0-3.992,4.069v1.787Z"
                                              fill="#d5a948" />
                                          </g>
                                        </g>
                                      </svg>
                                      {{ cust.fname }} {{ cust.lname }}
                                    </span>
                                  </p>
                                </div>
                              </label>
                              <!-- <span v-if="!cust.appointment.length" class="new">New</span> -->
                            </a>
                          </li>
                          <li class="green-bg px-3 py-1" v-if="addCustomerShowBtn">
                            <div class="text-start">
                              <a class="theme-btn-newlog yellow-btn text-white" @click="openModalAddCustomer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                  class="bi bi-plus" viewBox="0 0 16 16">
                                  <path
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                                    fill="white">
                                  </path>
                                </svg>
                                New customers
                              </a>
                            </div>
                          </li>
                        </ul>
                        <div v-if="customerNameSelected == '' ? false : true" class="form-group">
                          <label class="input-label">Customer</label>
                          <div class="dummy-md-one">
                            <div class="customer-name-new">
                              <p>
                                <span class="image-cro">
                                  <span class="backgraund-span">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.201" height="16.859"
                                      viewBox="0 0 15.201 16.859">
                                      <g id="Group_1632" data-name="Group 1632" transform="translate(4923.887 6021.4)">
                                        <g id="Group_1631" data-name="Group 1631">
                                          <path id="Path_1413" data-name="Path 1413"
                                            d="M-4916.286-6012.585a4.412,4.412,0,0,1-4.407-4.407,4.413,4.413,0,0,1,4.407-4.408,4.413,4.413,0,0,1,4.407,4.408A4.412,4.412,0,0,1-4916.286-6012.585Zm0-8.022a3.619,3.619,0,0,0-3.616,3.615,3.62,3.62,0,0,0,3.616,3.615,3.62,3.62,0,0,0,3.616-3.615A3.619,3.619,0,0,0-4916.286-6020.607Z"
                                            fill="#d5a948" />
                                          <path id="Path_1414" data-name="Path 1414"
                                            d="M-4923.887-6004.541l.038-2.617a4.866,4.866,0,0,1,4.861-4.86h.188a3.842,3.842,0,0,0,2.532,1,3.605,3.605,0,0,0,2.392-.9l.16-.089.171-.011a4.867,4.867,0,0,1,4.859,4.862v2.615Zm14.376-.792.035-1.825a4.083,4.083,0,0,0-4.02-4.067,4.28,4.28,0,0,1-2.772,1,4.4,4.4,0,0,1-2.8-1,4.084,4.084,0,0,0-3.992,4.069v1.787Z"
                                            fill="#d5a948" />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </span>
                                <span v-if="hideText"> {{ customerNameSelected }} </span>
                                <span v-if="hideTextSecond">
                                  {{ customerNameSelected.fname }} {{ customerNameSelected.lname }}
                                </span>
                                <a href="#" class="float-right" @click="removeCustomerselect()" id="glassAnimals">
                                  <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="#141b34" stroke-width="1.5">
                                      <path d="m14.9994 15-5.9994-6m.00064 6 5.99936-6" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                      <path
                                        d="m22 12c0-5.52285-4.4772-10-10-10-5.52285 0-10 4.47715-10 10 0 5.5228 4.47715 10 10 10 5.5228 0 10-4.4772 10-10z" />
                                    </g>
                                  </svg>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-none" :class="{ 'd-block': customerNameSelected }">
                        <div class="mt-0 pt-0">
                          <h4 class="heading-20-white add color-A">Service</h4>
                          <div class="booking-body-end">
                            <div v-if="showSelectedService == '' ? false : true" class="">
                              <div class="mb-3 ">
                                <div class="">
                                  <div class="service-listing" v-for="(catservice, index) in showSelectedService"
                                    :key="catservice.id">
                                    <label class="wi-label">
                                      <div class="service-box-second all package " style="display: block!important;">
                                        <div class="d-flex align-items-center">
                                          <figure class="side-padding-md-one m-0">
                                            <img class="package_md" :src="catservice.service_imageUrl" alt="service">
                                          </figure>
                                          <!-- <span :class="(cat.name=='Recent')?getClassColor(catservice.category_id):''"></span> -->
                                          <div class="text text-card-h6">
                                            <h4 :title="catservice.name" style="">{{ catservice.name }} </h4>

                                            <p :class="catservice.categories_id_color">{{ catservice.shortcode ?? 'M1'
                                              }}
                                              -
                                              {{ catservice.duration }}’</p>
                                          </div>
                                          <h6 class="ms-auto card-h6" style="">{{ catservice.price }} $
                                          </h6>
                                          <a class="float-right " @click="removeServiceSelect(index)" id="glassAnimals">
                                            <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <g stroke="#141b34" stroke-width="1.5">
                                                <path d="m14.9994 15-5.9994-6m.00064 6 5.99936-6" stroke-linecap="round"
                                                  stroke-linejoin="round" />
                                                <path
                                                  d="m22 12c0-5.52285-4.4772-10-10-10-5.52285 0-10 4.47715-10 10 0 5.5228 4.47715 10 10 10 5.5228 0 10-4.4772 10-10z" />
                                              </g>
                                            </svg>
                                          </a>
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="btns d-flex justify-content-center">
                              <button class="plus_ico" @click="openModalAddServiceNew">
                                Add Service
                              </button>
                            </div>
                          </div>
                        </div>
                        <ul>
                          <li class="text-danger error-message-addcutomer"
                            v-for="(errorArray, idx) in appointmentErrormsg" :key="idx">
                            {{ errorArray }}
                          </li>
                        </ul>

                        <div class="d-flex justify-content-around my-3">

                          <button class="theme-btn yellow-btn new-booking-button"
                            @click="bookAppointment('still_in_progress'); openModalAddTechNew();">Add
                            Tech</button>

                          <button type="submit"
                            class="theme-btn green-bg white-btn text-white button-booking-save text-white"
                            @click="bookAppointment('completed');">Complete</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="" id="add_serviseNew"
                  v-bind:style="{ display: showModaloneAddServiceNew ? 'block' : 'none' }"
                  v-if="showModaloneAddServiceNew">
                  <div class="">
                    <div class="booking-type text-center">
                      <p class="heading-20-white">Choose Service</p>
                    </div>

                    <div class="booking-body-end">
                      <div class="categories-list d-flex align-items-center">
                        <div class="listing">

                          <ul class="nav nav-tabs">

                            <li v-for="(cat, index) in category" :key="index">
                              <a class="category-li" data-toggle="tab" :href="'#home' + cat.id"
                                @click="activateTab(index)">{{
                                  cat.name
                                }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="tab-content">
                        <div :id="'home' + cat.id" class="container tab-pane " :class="{ active: activeTab === index }"
                          v-for="(cat, index) in category" :key="index">
                          <div class="service-listing" v-for="catservice in cat.servicelist" :key="catservice.id">
                            <label class="wi-label">
                              <input type="checkbox" name="" v-model="catservice.isSelected"
                                @change="handleCheckboxChange(catservice)" />
                              <div class="icon-box" :class="{ 'selected': catservice.isSelected }">
                                <div class="service-box all package d-block">
                                  <div class="d-flex align-items-center">
                                    <figure class="side-padding m-0"
                                      :style="{ margin: catservice.isSelected ? '-2px !important' : '' }">
                                      <img :src="catservice.service_imageUrl" alt="service"
                                        :style="{ width: catservice.isSelected ? '53px !important' : '', height: catservice.isSelected ? '53px !important' : '', borderRight: catservice.isSelected ? 'unset' : '' }">
                                    </figure>
                                    <div class="text text-card-h6">
                                      <h4 :title="catservice.name"
                                        :style="{ color: catservice.isSelected ? 'white!important' : '' }">
                                        {{
                                          catservice.name
                                        }}</h4>
                                      <p :class="catservice.categories_id_color">{{ catservice.shortcode ?? 'M1' }} - {{
                                        catservice.duration }}’</p>
                                    </div>
                                    <h6 class="ms-auto card-h6"
                                      :style="{ color: catservice.isSelected ? 'white!important' : '' }">{{
                                        catservice.price }} $
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between my-5 px-2">

                        <button class="theme-btn yellow-btn new-edit-ser-button"
                          @click="closeModalAddServiceNew">Cancel</button>

                        <button type="submit" class="theme-btn green-bg white-btn text-white button-edit-ser-save"
                          @click="closeModalAddServiceNew" id="glassAnimals">Done</button>
                      </div>
                    </div>

                  </div>
                </div>
                <div id="add_serviseNew" v-bind:style="{ display: showModalCustomerAddNew ? 'block' : 'none' }"
                  v-if="showModalCustomerAddNew">
                  <div>

                    <div class="booking-type text-center">
                      <p class="heading-20-white">New Customer</p>
                    </div>

                    <div class="booking-body-end">
                      <form class="helt-half" v-on:submit.prevent="save_customer">
                        <div class="new-customer-main d-flex flex-column">

                          <div class="new-customer-form">
                            <div class="form-group-50">
                              <input type="text" class="form-control" name="fname" placeholder="First Name"
                                autocomplete="off" v-model="fname">

                            </div>
                            <div class="form-group-50">
                              <input type="text" class="form-control" name="lname" placeholder="Last Name"
                                autocomplete="off" v-model="lname">
                            </div>
                            <div class="form-group bg-change-back">
                              <input type="text" class="form-control" name="phone" placeholder="Phone"
                                autocomplete="off" v-model="phone">
                            </div>
                          </div>
                          <ul>
                            <li class="text-danger error-message-addcutomer"
                              v-for="(errorArray, idx) in customerErrormsg" :key="idx">
                              {{ errorArray }}
                            </li>
                          </ul>
                          <div class="middle-space">

                            <button class="theme-btn yellow-btn new-edit-ser-button"
                              @click="closeModalAddCustomer">Cancel</button>

                            <button type="submit"
                              class="theme-btn green-bg white-btn text-white button-edit-ser-save">Done</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="" id="add_serviseNew" v-bind:style="{ display: showModaloneAddTechNew ? 'block' : 'none' }"
                  v-if="showModaloneAddTechNew">
                  <div>

                    <div class="booking-type text-center">
                      <p class="heading-20-white">Add Technician</p>
                    </div>

                    <div class="booking-body-end">

                      <div class="technician-listing">
                        <h4 class="text-g">Select Technician Avaiable</h4>

                        <label class="technician-check" v-for="tech in technicianList" :key="tech.id">
                          <input type="checkbox" v-model="techSelected" :value="tech.id" class="d-none"
                            @change="uniqueCheck">
                          <figure><img v-bind:class="(techSelected == tech.id) ? 'techImageSelect' : ''"
                              :src="tech.tech_imageUrl" :alt="tech.fname"></figure>
                          <p class="text-y">{{ tech.fname }} {{ tech.lname }}</p>
                          <span class="ms-auto">115’</span>
                        </label>

                      </div>
                      <div class="btns d-flex mt-4">
                        <button class="theme-btn yellow-btn mx-auto" @click="closeModalAddTechNew">Cancel</button>
                      </div>


                    </div>

                  </div>
                </div>


              </div>
              <!-- edit appointment start -->
              <div v-show="editFormData" class="booking_holding editBookingNewCustomerPopup"
                id="editBookingNewCustomer">
                <div id="booking-holding-one-s">
                  <div class="booking-type text-center">
                    <p class="heading-20-white">Edit Appoinment
                    </p>
                    <a href="#" class="float-right" @click="hideEditForm()" id="glassAnimals">
                      <svg data-v-e73c3a1a="" version="1.0" id="katman_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1436 1054"
                        xml:space="preserve">
                        <path data-v-e73c3a1a=""
                          d="M718.5,453.8l224-224.3c20.4-20.4,53.3-20.4,73.6,0c20.4,20.4,20.4,53.3,0,73.6l-224,224.6l224,224
                      c20.4,20.4,20.4,53.3,0,73.6c-20.4,20.4-53.3,20.4-73.6,0l-224-224l-224.6,224c-20.4,20.4-53.3,20.4-73.6,0
                      c-20.4-20.4-20.4-53.3,0-73.6l224-224L420.4,303.2c-20.5-20.4-20.5-53.3-0.1-73.6s53.3-20.4,73.6,0l224.6,224V453.8z">
                        </path>
                      </svg>
                    </a>
                  </div>
                  <div class="booking-body-end">
                    <form v-on:submit.prevent="">
                      <div>
                        <div class="form-group">
                          <label class="input-label">Customer</label>
                          <div class="dummy-md-one">
                            <div class="customer-name-new">
                              <p>
                                <span class="image-cro">
                                  <span class="backgraund-span">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.201" height="16.859"
                                      viewBox="0 0 15.201 16.859">
                                      <g id="Group_1632" data-name="Group 1632" transform="translate(4923.887 6021.4)">
                                        <g id="Group_1631" data-name="Group 1631">
                                          <path id="Path_1413" data-name="Path 1413"
                                            d="M-4916.286-6012.585a4.412,4.412,0,0,1-4.407-4.407,4.413,4.413,0,0,1,4.407-4.408,4.413,4.413,0,0,1,4.407,4.408A4.412,4.412,0,0,1-4916.286-6012.585Zm0-8.022a3.619,3.619,0,0,0-3.616,3.615,3.62,3.62,0,0,0,3.616,3.615,3.62,3.62,0,0,0,3.616-3.615A3.619,3.619,0,0,0-4916.286-6020.607Z"
                                            fill="#d5a948" />
                                          <path id="Path_1414" data-name="Path 1414"
                                            d="M-4923.887-6004.541l.038-2.617a4.866,4.866,0,0,1,4.861-4.86h.188a3.842,3.842,0,0,0,2.532,1,3.605,3.605,0,0,0,2.392-.9l.16-.089.171-.011a4.867,4.867,0,0,1,4.859,4.862v2.615Zm14.376-.792.035-1.825a4.083,4.083,0,0,0-4.02-4.067,4.28,4.28,0,0,1-2.772,1,4.4,4.4,0,0,1-2.8-1,4.084,4.084,0,0,0-3.992,4.069v1.787Z"
                                            fill="#d5a948" />
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </span>
                                {{ customerNameEdit }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-0 pt-0">
                        <h4 class="heading-20-white add color-A">Service</h4>
                        <div class="booking-body-end">
                          <div v-if="showSelectedService == '' ? false : true" class="">
                            <div class="mb-3 ">
                              <div class="">
                                <div class="service-listing" v-for="(catservice, index) in showSelectedService"
                                  :key="catservice.id">
                                  <label class="wi-label">
                                    <div class="service-box-second all package " style="display: block!important;">
                                      <div class="d-flex align-items-center justi">
                                        <figure class="side-padding-md-one m-0">
                                          <img class="package_md" :src="catservice.service_imageUrl" alt="service">
                                        </figure>
                                        <div class="text text-card-h6">
                                          <h4 :title="catservice.name" style="">{{ catservice.name }}</h4>
                                          <p :class="catservice.categories_id_color">{{ catservice.shortcode ?? 'M1' }}
                                            -
                                            {{
                                              catservice.duration }}’</p>
                                        </div>
                                        <h6 class="ms-auto card-h6" style="">{{ catservice.price }} $
                                        </h6>
                                        <a class="float-right " @click="removeServiceSelect(index)" id="glassAnimals">
                                          <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g stroke="#141b34" stroke-width="1.5">
                                              <path d="m14.9994 15-5.9994-6m.00064 6 5.99936-6" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                              <path
                                                d="m22 12c0-5.52285-4.4772-10-10-10-5.52285 0-10 4.47715-10 10 0 5.5228 4.47715 10 10 10 5.5228 0 10-4.4772 10-10z" />
                                            </g>
                                          </svg>
                                        </a>
                                      </div>

                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="btns d-flex justify-content-center">
                            <button class="plus_ico" @click="openModalAddServiceNew()">
                              Add Service
                            </button>
                          </div>
                        </div>
                      </div>
                      <ul>
                        <li class="text-danger error-message-addcutomer"
                          v-for="(errorArray, idx) in appointmentErrormsg" :key="idx">
                          {{ errorArray }}
                        </li>
                      </ul>

                      <div class="btns d-flex justify-content-around pt-2">
                        <button class="theme-btn yellow-btn new-edit-button"
                          @click="addTechAppointment('still_in_progress')">Add
                          Tech</button>
                        <button type="submit" class="theme-btn green-bg white-btn text-white button-edit-save"
                          @click="update_appointment();">Save</button>
                      </div>
                      <!-- <div v-if="isModalVisible" class="sticky-alert">
                        <div class="alert-content">
                          <span @click="closeAlert" class="close">&times;</span>
                          Please select the technician for Customer Name
                        </div>
                      </div> -->
                    </form>
                  </div>
                </div>
                <div class="sss" id="add_serviseNewEdit"
                  v-bind:style="{ display: showModaloneAddServiceNew ? 'block' : 'none' }"
                  v-if="showModaloneAddServiceNew">
                  <div class="">

                    <div class="booking-type text-center">
                      <p class="heading-20-white">Choose Service</p>
                    </div>

                    <div class="booking-body-end">
                      <div class="categories-list d-flex align-items-center">
                        <div class="listing">
                          <ul class="nav nav-tabs">
                            <li v-for="(cat, index) in category" :key="index">
                              <a class="category-li" data-toggle="tab" :href="'#home' + cat.id"
                                @click="activateTab(index)">{{
                                  cat.name }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <!-- service tage -->
                      <div class="tab-content">

                        <div :id="'home' + cat.id" class="container tab-pane " :class="{ active: activeTab === index }"
                          v-for="(cat, index) in category" :key="index"><br>
                          <div class="service-listing" v-for="catservice in cat.servicelist" :key="catservice.id">
                            <label class="wi-label">
                              <input type="checkbox" name="" v-model="catservice.isSelected"
                                @change="handleCheckboxChange(catservice)" />
                              <div class="icon-box" :class="{ 'selected': catservice.isSelected }">
                                <div class="service-box all package d-block"
                                  :style="{ border: catservice.isSelected ? '1px solid #d5a948' : '' }">
                                  <div class="d-flex align-items-center">
                                    <figure class="side-padding m-0"
                                      :style="{ margin: catservice.isSelected ? '-2px !important' : '' }">
                                      <img :src="catservice.service_imageUrl" alt="service"
                                        :style="{ width: catservice.isSelected ? '53px !important' : '', height: catservice.isSelected ? '53px !important' : '' }">
                                    </figure>
                                    <div class="text text-card-h6">
                                      <h4 :title="catservice.name"
                                        :style="{ color: catservice.isSelected ? 'white!important' : '' }">
                                        {{
                                          catservice.name
                                        }}</h4>

                                      <p :class="catservice.categories_id_color">{{ catservice.shortcode ?? 'M1' }} - {{
                                        catservice.duration }}’</p>
                                    </div>
                                    <h6 class="ms-auto card-h6"
                                      :style="{ color: catservice.isSelected ? 'white!important' : '' }">{{
                                        catservice.price }} $
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex justify-content-between my-5 px-2">

                        <button class="theme-btn yellow-btn new-edit-ser-button"
                          @click="closeModalAddServiceEdit">Cancel</button>

                        <button type="submit" class="theme-btn green-bg white-btn text-white button-edit-ser-save"
                          @click="closeModalAddServiceEdit" id="glassAnimals">Done</button>
                      </div>
                    </div>

                  </div>
                </div>



              </div>

              <!-- edit appointment end -->
            </div>
          </div>
          <!-- <InActiveTechList :technician="technician"/> -->
        </div>
      </div>

      <div v-show="isModalVisible" class="sticky-alert" id="isModalVisible">
        <div class="alert-content">
          <span @click="closeAlertCustomerName" class="close">&times;</span>
          Please select the technician for {{ this.customerNameEdit }}
        </div>
      </div>
      <div v-if="moveConditionSetActivated" class="sticky-alert">
        <div class="alert-content">
          Please select the technician to move
        </div>
      </div>
      <div class="sticky-alert" v-if="isMovePopVisible">
        <div class="alert-content">
          <div class="alert-text">You want to move Appointment {{ moveCustomerName }} <span>from</span> {{
            movetechnicianName }}, {{ moveappointmentTime
            }} <span>to</span> {{ movetoTechnicianName }} {{ selectedTime }} </div>
          <div class="text-one">
            <a class="btn-icon-status" @click="moveAppointmentCheck('closePopup')"><i class="fa-solid fa-xmark"></i></a>
            <a class="btn-icon-status-one px-5" @click="moveAppointmentCheck('moveAppointment')"><i
                class="fa-solid fa-check"></i></a>
          </div>

        </div>
      </div>
    </section>

  </div>
</template>

<script>
import HorizonCalendar from './HorizonCalendar.vue'
import InActiveTechList from './InActiveTechList.vue'
import ModelCards from './ModelCards.vue'
import { ref } from 'vue';
import Swal from 'sweetalert2'
import moment from "moment";


export default {
  components: {
    HorizonCalendar,
    InActiveTechList,
    ModelCards
  },

  data() {
    return {
      hours: Array.from({ length: 14 }, (_, i) => 8 + i),
      dateGet: new Date(),
      showModalone: false,
      showModaloneAddService: false,
      showModaloneAddServiceNew: false,
      showModaloneAddTech: false,
      showModalCustomerAddNew: false,
      showModaloneAddTechNew: false,
      technician: [],
      category: [],
      searchValue: '',
      hideText: true,
      hideTextSecond: false,
      customer: '',
      activeTab: 0,
      category_id: '',
      selectedCustomer: [],
      barGraphData: '',
      latestBarGraphData: '',
      totalAppointmentRecord: [],
      openPopIdGet: '',
      customerNameSelected: '',
      customerNameSelectedupdate: '',
      fname: '',
      lname: '',
      phone: '',
      customerErrormsg: [],
      addCustomerShowBtn: false,
      techSelected: '',
      technicianList: [],
      isServiceSelected: '',
      showSelectedService: [],
      selectedTime: '',
      showTimeFormate: '',
      customerId_choose: '',
      expandedSection: null,
      showCheckboxes: false,
      activeAccordion: null,
      appointmentErrormsg: [],
      hideUserDataMain: false,
      paymentType: null,
      paymentAmount: null,
      bargraphDataClicked: false,
      selectedBarGraphData: null,
      appointmentIDMove: null,
      technicianMoveHtml: null,
      pastDataIdFetch: null,
      conditionalMovepopUp: false,
      currentTechnician: {},
      isModalVisible: false,
      isBarDisabled: true,
      appointmentIDEdit: null,
      technicianEditHtml: null,
      editCustomerId: null,
      editTechnicianId: null,
      appointmentTime: '',
      relatedAppointmentID: null,
      appointmentBackedsaved: null,
      isMovePopVisible: false,
      moveConditionSetActivated: false,
      moveappointmentTime: null,
      moveCustomerName: null,
      movetechnicianName: null,
      movetoTechnicianName: null,
      searchQuery: '',
      removeIcoButton: true,
      editFormData: false,
      classColor: null,
      customerNameEdit: '',
      dynamicHeightCalculate: 0,
      dynamicData: 0,
      lastOpenElement: null,
      isOverlayVisible: false,
      showFirstModal: false,
      storedTechnicianId: null,
      selectedAppointment: '',
      appointementList: [],
      totalAppointmentPrice: 0,
      remainingAmount: 0,
      appPoinId: null,
      customerDetail: {},
      appointWhenNull: null,
      showNewAddCustomer: false,
      saveTechId: '',
      saveApId: '',
    }
  },
  created() {
    this.dateGet = moment(this.dateGet).format('YYYY-MM-DD');
    this.technicianEditHtml = document.getElementsByClassName("editBookingNewCustomerPopup");
    axios.get('/api/category?user_id=' + localStorage.getItem('usertoken'))
      .then((resp) => {
        this.category = resp.data.category

        let dummyCat = {
          id: 0,
          name: 'Recent',
          servicelist: resp.data.serviceListData
        }
        this.category.push(dummyCat);
        this.category.reverse();

      }),
      axios.get('/api/technician-graph-data?user_id=' + localStorage.getItem('usertoken') + '&dateGet=' + this.dateGet)
        .then((resp) => {
          this.technician = resp.data.technician;
          this.barGraphData = resp.data.dataBargraph;
          this.latestBarGraphData = resp.data.technicianGraphData;
          this.totalAppointmentRecord = resp.data.totalCountAppointment;

        });
  },
  mounted() {
  },
  computed: {
    isButtonDisabled() {
      return (technicianId, time) => {
        for (const bargraphData of this.latestBarGraphData) {
          if (bargraphData.technicianID === technicianId) {
            for (const graphData of bargraphData.data) {
              if (graphData.time === time) {
                return true;
              }
            }
          }
        }
        return false;
      };
    },
    shouldShowBarsRel() {
      return this.latestBarGraphData.some(data => data.technicianID === this.tech.technician_id);
    }
  },
  methods: {
    // getTechIdAddTechnician(techId) {
    //   this.saveTechId = techId;
    //   Swal.fire({
    //     title: 'Are you sure?',
    //     text: "Do you want to change the technician for this appointment?",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, update it!',
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       this.fetchTechnicianList();
    //     }
    //   });
    // },
    showLoader() {
      document.querySelector('#loader').style.display = 'block';
    },
    hideLoader() {
      document.querySelector('#loader').style.display = 'none';
    },
    refreshGraph() {
      this.refreshBarGraphData()
    },
    updateTotalAppointmentPrice(data) {
      this.totalAppointmentPrice = parseInt(this.totalAppointmentPrice) + parseInt(data);
    },
    handleAddOnServices(data) {
      this.showSelectedService = [...this.showSelectedService, ...data]
      this.update_appointmentFromModal(this.showSelectedService);
      this.fetch_appointment();
    },
    closeFirstModal() {
      this.showFirstModal = false;
    },
    openFirstModal(id) {
      $(document).ready(() => {
        $('#openModelCheckout').modal('show');
        this.hideMod();
        this.selectedAppointment = id
        this.showFirstModal = true;
      });
    },
    toggleOverlay() {
      this.hideMod();
      this.isOverlayVisible = false;
      var elementM = document.getElementById('userData' + this.openPopIdGet);
      if (elementM) {
        elementM.classList.remove('showModel');
      }
      console.log('elementMisOverlayVisible', elementM);
    },
    isElementVisible(element) {
      const style = window.getComputedStyle(element);
      return style.display !== 'none' && style.visibility !== 'hidden';
    },
    clickeventAction(event, bargraphData, technician_id, index) {
      const target = event.target;
      this.bargraphData = bargraphData;
      this.bargraphDataClicked = !this.bargraphDataClicked;
      this.selectedBarGraphData = bargraphData;
      this.moveConditionSetActivated = false;
      for (let i = 0; i < this.latestBarGraphData.length; i++) {
        if (this.latestBarGraphData[i].technicianID == technician_id && this.latestBarGraphData[i].dynamicHeight && this.latestBarGraphData[i].dynamicHeight.length) {
          this.dynamicHeightCalculate = this.latestBarGraphData[i].dynamicHeight[index];
          this.dynamicData = this.latestBarGraphData[i].data[index];
          const conditionMet = true;
          this.openPopIdGet = technician_id;
          if (conditionMet) {
            const targetId = "#userData" + technician_id;
            $(targetId).addClass('showModel');
          }
        }
      }
      const root = document.querySelector(':root');
      root.style.setProperty('--dynamicHeightCalculate', this.dynamicHeightCalculate + "px");
      $(".show-bar-border_" + this.appointmentBackedsaved).removeClass('bar-graph-border-area');
      if (event.target.getAttribute('data-id-appointment') != null && event.target.getAttribute('data-id-appointment') != '') {
        this.appointmentBackedsaved = event.target.getAttribute('data-id-appointment');
        $(".show-bar-border_" + event.target.getAttribute('data-id-appointment')).addClass('bar-graph-border-area');
      }
      if (target.classList.contains('collaped-md')) {
        let techId_split = event.target.getAttribute('data-bs-target').split('_')[1];
        this.openPopIdGet = techId_split;
        $('#userData' + techId_split).collapse('toggle');
        const elementsToShow = document.querySelectorAll('.collapse.show');
        elementsToShow.forEach(element => {
          element.classList.remove('show');
        });
      }

      if (event.target.hasAttribute("data-id-appointment")) {
        // this.isOverlayVisible = true;
      }

      if (event.target.hasAttribute("data-id-type")) {
        this.isOverlayVisible = true;
      }
      if (target.classList.contains('dynamic-html')) {
        console.log("here_one");
        // $("#userData" + event.target.getAttribute('data-id')).collapse('show');
      }
      if (target.classList.contains('accept-btn-action-html')) {
        this.updateAppointmentStatus(event.target.getAttribute('data-id'), 'accept');
      } else if (target.classList.contains('checkin-btn-action-html')) {
        this.updateAppointmentStatus(event.target.getAttribute('data-id'), 'check in');
      }
      else if (target.classList.contains('served-btn-action-html')) {
        this.updateAppointmentStatus(event.target.getAttribute('data-id'), 'served');
      }
      else if (target.classList.contains('payment-btn-action-html')) {
        // $("#collapse_accept_amount" + event.target.getAttribute('data-id')).collapse('hide');
        //this.updateAppointmentStatus(event.target.getAttribute('data-id'), 'payment');
        $("#collapse_new" + event.target.getAttribute('data-id')).collapse('hide');

      }
      else if (target.classList.contains('removePopup-btn-action-html')) {
        this.toggleOverlay();
        $("#collapse_new" + event.target.getAttribute('data-id')).collapse('toggle');
        $("#collapse_accept_amount" + event.target.getAttribute('data-id')).collapse('hide');
      }
      else if (target.classList.contains('edit-btn-action-html')) {
        this.technicianEditHtml = document.getElementsByClassName("editBookingNewCustomerPopup");
        this.appointmentIDEdit = event.target.getAttribute('data-id');
        this.saveApId = this.appointmentIDEdit;
        this.fetch_appointment(this.appointmentIDEdit);
        if (this.pastDataIdFetch == null) {
          this.pastDataIdFetch = event.target.getAttribute('data-id-type');
        } else {
          //let element = document.getElementsByClassName('editBookingNewCustomer');

        }
        // $("#collapse_new" + event.target.getAttribute('data-id-type')).append(this.technicianEditHtml);
        //this.pastDataIdFetch = event.target.getAttribute('data-id-type');
        const dataIdType = event.target.getAttribute('data-id-type');
        if (dataIdType) {
          // Append technicianEditHtml to the target collapse element
          $("#collapse_new" + dataIdType).append(this.technicianEditHtml);
        } else {
          console.warn("data-id-type attribute is missing");
        }
      }
      else if (target.classList.contains('move-btn-action-html')) {
        this.appointmentIDMove = event.target.getAttribute('data-id');
        this.conditionalMovepopUp = true;
        this.toggleOverlay();
        if (this.pastDataIdFetch == null) {
          this.pastDataIdFetch = event.target.getAttribute('data-id-type');
        } else {

        }
        this.pastDataIdFetch = event.target.getAttribute('data-id-type');
        this.moveConditionSetActivated = true;
        this.fetchCustomerTechnicianData();
        $("#collapse_new" + event.target.getAttribute('data-id-type')).collapse('toggle');
      }
      else if (target.classList.contains('checkout-btn-action-html')) {
        let data = {
          id: event.target.getAttribute('data-id'),
          status: 'checkout',
          total_amount: this.paymentAmount,
          payment_type: this.paymentType

        }
        this.selectedAppointment = data.id
        //this.updateAppointmentStatusCheckOut(data);
        //this.updateAppointmentStatusCheckOut();
        this.openFirstModal(data.id);
        this.fetch_appointment(data.id)
        this.fetchAppointments(data.id)
        this.toggleOverlay();

      }
      else if (target.classList.contains('cancel-btn-action-html')) {
        Swal.fire({
          title: 'Are you sure you want to cancel appointment?',
          showCancelButton: true,
          confirmButtonColor: '',
          cancelButtonColor: '',
          confirmButtonText: '<i class="fa fa-check"></i>',
          cancelButtonText: '<i class="fa fa-times"></i>'
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateAppointmentStatus(event.target.getAttribute('data-id'), 'cancel');
          } else {
            this.toggleOverlay();
          }
        });
      }

    },
    fetchAppointments(id) {
      axios.post('/api/fetch-all-appointment-details', { appointment_id: id })
        .then(response => {
          if (response.status == 200) {
            // console.log(response.data)
            // const services = response.data.services
            // this.totalAppointmentPrice = parseFloat(response.data.appointment.total_amount)
            // this.remainingAmount = this.totalAppointmentPrice
            // this.appointementList = services.map((service) => ({
            //   id: service.id,
            //   name: service.service_name,
            //   price: service.price
            // }))
            // this.showSelectedService = response.data.services
            // // console.log("fetchAppointments(id)",this.showSelectedService)
            this.appointmentIDEdit = id;
            this.dateGet = response.data.appointment.appointment_date;
            this.selectCustomer(response.data.appointment.customer_id);
            this.editCustomerId = response.data.appointment.customer_id;
            this.editTechnicianId = response.data.appointment.technician;
          }
        })
        .catch(error => {
          console.error(error);
          Swal.fire({
            title: "No Appointment found",
          });
        });
    },
    update_appointmentFromModal(data) {
      const requestData = {
        appointment_id: this.appointmentIDEdit,
        appoint_time: this.selectedTime,
        appointment_date: this.dateGet,
        customer_id: this.editCustomerId,
        serviceData: data,
        technicianselect: this.editTechnicianId,
        user_id: localStorage.getItem('usertoken')
      };
      axios.post('/api/update-appointment', requestData)
        .then(response => {
          if (response.status == 200) {
            Swal.fire({
              text: "Appointment Updated successfully!",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            })
          }
          this.refreshBarGraphData();
        })
        .catch(error => {
          console.error(error);
          Swal.fire({
            title: "No Appointment found",
          });
        });
    },
    update_appointment() {
      this.dateGet = moment(this.dateGet).format('YYYY-MM-DD');
      const requestData = {
        appointment_id: this.appointmentIDEdit,
        appoint_time: this.selectedTime,
        appointment_date: this.dateGet,
        customer_id: this.editCustomerId,
        serviceData: this.showSelectedService,
        technicianselect: this.editTechnicianId,
        user_id: localStorage.getItem('usertoken')
      };

      axios
        .post('/api/update-appointment', requestData,

          {
            headers: {
              "Accept": "application/json",
            },
          }
        )
        .then((resp) => {
          this.notifmsg = [];

          if (resp['data']['data']) {
            this.showSelectedService = [];
            Swal.fire({
              text: "Appointment Updated successfully!",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            }).then(() => {
              // setTimeout(() => {
              //   window.location.reload();
              //   this.$router.push('/dashboard?date=' + this.dateGet);
              // }, 1000);
            });
            this.appointmentErrormsg = [];
            // this.refreshBarGraphData();
            this.hideMod(this.openPopIdGet);
            this.$refs.horizonCalendarAction.takingAction();
            this.selectedTime = '';
            this.openPopIdGet = '';

            this.showSelectedService = [];

            this.callBackcategoriesApi();
            this.refreshBarGraphData();
          }
          else {

            Swal.fire({
              title: 'Oops',
              text: "error",
            }
            );

          }

        })
        .catch(e => {
          this.appointmentErrormsg = e.response.data
        })
    },
    fetch_appointment(id) {
      this.showLoader();
      if (id) {
        this.appointmentIDEdit = id
      }
      axios
        .get('/api/get-appointment?appointment_id=' + this.appointmentIDEdit,

          {
            headers: {
              "Accept": "application/json",
            },
          }
        )
        .then((resp) => {
          // console.log("this.edit appointment", resp);
          this.notifmsg = [];
          this.technicianid = '';
          this.customerNameEdit = resp.data.customer.fname + ' ' + resp.data.customer.lname;
          // this.searchValue = '';
          // this.getData();
          //  $('.search_customer_mobile').keyup();
          if (resp.data.services && resp.data.services.length > 0) {
            const services = resp.data.services;
            this.appPoinId = resp.data.id;

            this.customerDetail = resp.data.customer;
            this.totalAppointmentPrice = parseFloat(resp.data.total_amount);
            this.remainingAmount = this.totalAppointmentPrice;
            this.appointementList = services.map((service) => ({
              id: service.id,
              name: service.service_name,
              price: service.price
            }))
            this.showSelectedService = resp.data.services;
            this.editCustomerId = resp.data.customer_id;
            this.customerNameSelected = {
              id: resp.data.customer.id,
              fname: resp.data.customer.fname,
              lname: resp.data.customer.lname,
              phone: resp.data.customer.phone,

            };
            // console.log("this.customerNameSelected:22", this.customerNameSelected);
            this.editTechnicianId = resp.data.technician;
            this.appointmentTime = resp.data.appointment_time;
            // console.log("this.appointmentTime:", this.appointmentTime);
            const service = resp.data.services[0];
            this.editFormData = true;
            this.$nextTick(() => {
              let ele = document.getElementById('booking-holding-one-s');
              if (ele) {
                ele.style.display = 'block';
              }

              let el = document.getElementById('editBookingNewCustomer');
              if (el) {
                el.style.display = 'block';
              }
              this.hideLoader();
            });
          } else {
            Swal.fire({
              text: "Please add services to the appointment first.", type: "info"
            })
          }
        })
        .catch(e => {
          this.notifmsg = e.response.data
        })
    },
    getClassColor(catId) {
      this.category.map((data) => {
        if (data.id == catId) {
          this.classColor = data.color;
          // return this.classColor= data.color;
        }
        else {
          this.classColor = '';
        }
      })
    },

    pickupActionValueKeyUp(event) {
      const target = event.target;

      if (target.classList.contains('card_amount_paid')) {
        this.paymentType = 'card';
        this.paymentAmount = target.value;
      }
      if (target.classList.contains('cash_amount_paid')) {
        this.paymentType = 'cash';
        this.paymentAmount = target.value;
      }
    },

    toggleCollapse(technician_id) {
      this.storedTechnicianId = technician_id;
      const collapseId = 'userData' + technician_id;
      const collapseElement = document.getElementById(collapseId);
      this.hideUserDataMain = true;
      if (collapseElement) {
        collapseElement.classList.toggle('show');
      }
    },
    isTouchDevice() {
      return 'ontouchstart' in window || navigator.maxTouchPoints;
    },
    openModal() {
      this.showModalone = true;
      this.customerErrormsg = [];
      this.customer = '';
      this.searchValue = '';
    },
    closeModal() {
      this.showModalone = false;
    },
    openModalAddService() {
      this.showModaloneAddService = true;
    },

    closeModalAddService() {
      this.showModaloneAddService = false;
    },
    openModalAddServiceNew() {
      let el = document.getElementById('booking-holding-one');
      if (el) {
        el.style.display = 'none';
      }
      let ele = document.getElementById('booking-holding-one-s');
      if (ele) {
        ele.style.display = 'none';
      }

      this.showModaloneAddServiceNew = true;

    },
    // openModalAddServiceNewEdit() {
    //   let el = document.getElementById('booking-holding-one-s');
    //   if (el) {
    //     el.style.display = 'none';
    //   }
    //  // this.showModaloneAddServiceNew = true;
    // }
    // ,
    closeModalAddServiceNew() {
      this.showModaloneAddServiceNew = false;
      let el = document.getElementById('booking-holding-one');
      if (el) {
        el.style.display = 'block';
      }
    },
    closeModalAddServiceEdit() {
      this.showModaloneAddServiceNew = false;
      let el = document.getElementById('booking-holding-one-s');
      if (el) {
        el.style.display = 'block';
      }
    },
    openModalAddCustomer() {
      this.showModalCustomerAddNew = true;
      this.customerErrormsg = [];
      //this.customer = '';
      //this.searchValue = '';
      let el = document.getElementById('booking-holding-one');
      if (el) {
        el.style.display = 'none';
      }
    },
    closeModalAddCustomer() {
      this.showModalCustomerAddNew = false;
      let el = document.getElementById('booking-holding-one');
      if (el) {
        el.style.display = 'block';
      }
    },
    openModalAddTech() {
      this.showModaloneAddTech = true;
    },
    closeModalAddTechNew() {
      this.showModaloneAddTechNew = false;

      let el = document.getElementById('booking-holding-one');
      if (el) {
        el.style.display = 'block';

      }

    },
    openModalAddTechNew() {
      this.isModalVisible = true;
      // console.log("Element displayed: this.isModalVisible", this.isModalVisible);
      // let el = document.getElementById('editBookingNewCustomer');
      // if (el) {
      //   el.style.display = 'block';
      //   console.log("Element displayed:", el);
      // }
      let elb = document.getElementById('isModalVisible');
      if (elb) {
        elb.style.display = 'block';
        // console.log("Element displayed: add", elb);
      }
      // let ele = document.getElementById('bookingNewCustomer');
      // if (ele) {
      //   ele.style.display = 'none';
      //   console.log("Element displayed:", ele);
      // }
      //this.addTechAppointment(app_type);
      //this.toggleOverlay();

      //this.showModaloneAddTechNew = true;
      // let el = document.getElementById('bookingNewCustomer');
      // if (el) {
      //   el.style.display = 'none';
      // }
      // let ele = document.getElementById('booking-holding-one');
      // if (ele) {
      //   ele.style.display = 'block';
      // }
      // setTimeout(() => {
      //   this.closeAlert();
      // }, 10000);
    },
    closedModalAddTechNew() {
      let elb = document.getElementById('isModalVisible');
      if (elb) {
        elb.style.display = 'none';
        console.log("Element displayed:", elb);
      }
    },
    closeAlertCustomerName() {
      this.isModalVisible = false;
    },

    activateTab(index) {
      this.activeTab = index;
    },
    getCategoryId(id) {
      this.category_id = id;
    },
    hideEditForm() {
      this.editFormData = false;
    }
    ,
    removeCustomerSearch() {
      this.removeIcoButton = false;
      this.customer = '';
      this.searchValue = '';
    },
    getData() {
      this.removeIcoButton = true;
      this.addCustomerShowBtn = false;
      this.selectedCustomer = [];
      if (this.searchValue.length > 1) {
        let ele = document.getElementById('bg_white_one');
        if (ele) {
          ele.style.display = 'block';
        }
        this.addCustomerShowBtn = true;
        axios.get('/api/searchuser?user_id=' + localStorage.getItem('usertoken') + '&number=' + this.searchValue)
          .then((resp) => {
            this.customer = resp.data.customer;
            this.$nextTick(() => {
              const iconElement = document.querySelector('.icon_change');
              if (iconElement) {
                iconElement.classList.add('new-Icon-change');
              }
            });
          })
        this.phone = this.searchValue;
      }
      else {
        this.customer = '';
        this.phone = '';
      }

    },

    formatHour(hour) {
      const amPm = hour < 12 ? "AM" : "PM";
      const number = moment(hour, ["HH"]).format("hh:mm A");
      //  return number ; 
      return `${hour.toString().padStart(2, "0")}${amPm}`;

    },
    formatHourAm_Pm(hour) {
      const amPm = hour < 12 ? "AM" : "PM";
      const number = moment(hour, ["HH"]).format("hh:mm A");
      return number;
      //return `${hour.toString().padStart(2, "0")}${amPm}`;
    },
    formatHourMinute(hour, minutes) {
      const amPm = hour < 12 ? "AM" : "PM";
      const number = moment(hour + '.' + minutes, ["HH.mm"]).format("hh:mm A");
      return number;
      //return `${hour.toString().padStart(2, "0")}:${minutes} ${amPm}`;
    },
    showModel(id, timev) {
      const collapseElementBefore = document.getElementById('userData' + this.openPopIdGet);
      // $('#userData' + this.openPopIdGet).collapse('hide');
      this.appointmentErrormsg = [];
      if (collapseElementBefore) {
        const elementsToShow = document.querySelectorAll('.collapse.show');
        elementsToShow.forEach(element => {
          element.classList.remove('show');
        });
      }

      this.hideUserDataMain = false;
      this.openPopIdGet = id;
      this.$emit('onOpenPopIdUpdated', id);
      this.selectedTime = moment(timev, ["hh:mm a"]).format("HH:mm A");

      this.showTimeFormate = timev;

      if (this.relatedAppointmentID != null) {
        this.openModalAddServiceNew();
      }
      if (this.moveConditionSetActivated == true) {
        this.isMovePopVisible = true;
      }
      else {
        const collapseId = 'userData' + id;
        const collapseElement = document.getElementById(collapseId);

        this.hideUserDataMain = true;
        if (collapseElement) {
          collapseElement.classList.toggle('show');
        }
        // $('#userData' + id).collapse('toggle');
        $('.collapseCustomBar_' + id).collapse('toggle');
      }
      this.showSelectedService = [];

    },
    hideMod(id) {
      
      const elementsToShow = document.querySelectorAll('.collapse.show');
      elementsToShow.forEach(element => {
        element.classList.remove('show');
      });

      for (let i = 8; i <= 20; i++) {
        $('#' + i).css('display', 'block');
      }
      $('#userData' + id).collapse('hide');
      $('#collapsePinkBar_' + id).collapse('hide');

      const expandedElements = document.querySelectorAll('[aria-expanded="true"]');
      expandedElements.forEach(element => {
        element.setAttribute('aria-expanded', 'false');
      });

      let elementM = $('#userData' + this.openPopIdGet);
      if (elementM.length) {
        elementM.removeClass('showModel');
      }
      this.editFormData = '';
      this.refreshBarGraphData();
      this.isModalVisible = false;
      var element = document.getElementById('bookingNewCustomer');
      if (element) {
        element.style.display = 'none';
      }
    },
    generateBarStyle(hour, minute) {
      const timeInMinutes = hour * 60 + minute;
      const duration = 15;
      const color = 'grey';
      const height = `${duration * 3}px`;
      const marginTop = `${timeInMinutes * 3 + 100}px`;
      return `height: ${height}; margin-top: ${marginTop}; background-color: ${color};`;
    },

    selectCustomer(cust) {
      // console.log(this.selectedCustomer.indexOf(cust), " this.selectedCustomer")
      if (this.isSelected(cust)) {

        const index = this.selectedCustomer.indexOf(cust);
        if (index !== -1) {
          this.selectedCustomer.splice(index, 1);
        }
      } else {
        let customerChoose = this.customer.find(customer => {

          if (customer.id === cust) {
            return customer;
          }
        });
        this.customerNameSelected = customerChoose.fname + ' ' + customerChoose.lname;
        this.selectedCustomer = [];
        this.addCustomerShowBtn = false;
        this.selectedCustomer.push(cust);
        setTimeout(() => {
          this.customer = '';
          this.searchValue = '';
          this.openModalAddServiceNew();
        }, 1000);

      }
      // this.selectedCustomer = cust;
      this.customerId_choose = cust;
      // console.log("is.customerId", this.customerId_choose);
    },
    // selectCustomer(cust) {
    //   console.log("Selected Customer ID:", cust);
    //   console.log("Current Customer Object:", this.customer);

    //   if (this.isSelected(cust)) {
    //     const index = this.selectedCustomer.indexOf(cust);
    //     if (index !== -1) {
    //       this.selectedCustomer.splice(index, 1);
    //     }
    //   } else {
    //     // Check if this.customer exists and has an id property
    //     if (this.customer && this.customer.id === cust) {
    //       let customerChoose = this.customer;

    //       // Use customerChoose directly as it's the selected customer
    //       if (customerChoose) {
    //         this.customerNameSelected = customerChoose.fname + ' ' + customerChoose.lname;
    //         this.selectedCustomer = [];
    //         this.addCustomerShowBtn = false;
    //         this.selectedCustomer.push(cust);

    //         setTimeout(() => {
    //           this.customer = '';
    //           this.searchValue = '';
    //           this.openModalAddServiceNew();
    //         }, 1000);
    //       } else {
    //         console.error("Customer not found");
    //       }
    //     } else {
    //       console.error("this.customer is not found or id does not match");
    //     }
    //   }

    //   this.customerId_choose = cust;
    //   console.log("is.customerId", this.customerId_choose);
    // },

    isSelected(cust) {
      return this.selectedCustomer.includes(cust);
    },
    refreshBarGraphData() {
      $("#booking-holding-one").append(this.technicianEditHtml);
      this.conditionalMovepopUp = false;
      axios.get('/api/technician-graph-data?user_id=' + localStorage.getItem('usertoken') + '&dateGet=' + this.dateGet)
        .then((resp) => {
          this.technician = resp.data.technician;
          this.barGraphData = resp.data.dataBargraph;
          this.latestBarGraphData = resp.data.technicianGraphData;
          this.totalAppointmentRecord = resp.data.totalCountAppointment;
        });

      if (this.openPopIdGet != '' && this.openPopIdGet != null) {
        const collapseId = 'userData' + this.openPopIdGet;
        const collapseElement = document.getElementById(collapseId);
        $('#userData' + this.openPopIdGet).collapse('hide');
      }
    },
    removeCustomerselect() {
      this.customerNameSelected = '';
      this.customerId_choose = '';
    },
    uniqueCheck(e) {
      this.techSelected = e.target.value;
      setTimeout(() => {
        this.closeModalAddTechNew();
      }, 1000);

    },
    fetchTechnicianList() {
      const appointment_id = this.saveApId;

      axios.put(`/api/appointments/${appointment_id}/technician`,
        {
          technician_id: this.saveTechId,
        })
        .then((response) => {
          this.message = response.data.message;
          this.isModalVisible = false;
          this.refreshBarGraphData();
          this.error = '';
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.error) {
            this.error = err.response.data.error;
          } else {
            this.error = 'An error occurred.';
          }
          this.message = '';
        });
    },
    fetchCustomerTechnicianData() {
      axios.get('/api/get-appointment?appointment_id=' + this.appointmentIDMove)
        .then((resp) => {
          this.moveappointmentTime = resp.data.appointment_time;
          this.moveCustomerName = resp.data.customer.fname + ' ' + resp.data.customer.lname;
          this.movetechnicianName = resp.data.technician_d.fname + ' ' + resp.data.technician_d.lname;
          this.searchValue = resp.data.cust_number;
        })
    },

    removeServiceSelect(index) {
      this.showSelectedService[index].isSelected = false;
      this.showSelectedService.splice(index, 1);
    },
    save_customer() {
      axios
        .post('/api/customer',
          {
            fname: this.fname,
            lname: this.lname,
            phone: this.phone,
            user_id: localStorage.getItem('usertoken')
          },
          {
            headers: {
              "Accept": "application/json",
            },
          }
        )
        .then((resp) => {
          this.customerNameSelected = this.fname + ' ' + this.lname;
          this.fname = '';
          this.lname = '';
          this.phone = '';
          this.customerErrormsg = [];
          if (resp['data']['customer']) {
            this.customerId_choose = resp['data']['customer']['id'];
            this.fname = '';
            this.lname = '';
            this.phone = '';

            let ele = document.getElementById("bg_white_one");
            if (ele) {
              ele.style.display = 'none';
            }
            Swal.fire({
              text: "Customer Created Successfully!",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            }).then(() => {
              this.closeModalAddCustomer();
              this.openModalAddServiceNew();
            })
            this.showModalone = false;
            this.addCustomerShowBtn = false;
            //this.customerNameSelected = '';
          }
          else {

            Swal.fire({
              title: 'Oops',
              text: "error",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true

            });

          }

        })
        .catch(e => {
          this.customerErrormsg = e.response.data
        })
    },
    handleCheckboxChange(catservice) {
      this.isServiceSelected = catservice.isSelected;
      if (catservice.isSelected) {
        this.showSelectedService.push(catservice);
        // console.log(this.showSelectedService, "isSelected");
      } else {
        const indexToRemove = this.showSelectedService.findIndex(service => service.id === catservice.id);
        if (indexToRemove !== -1) {
          this.showSelectedService.splice(indexToRemove, 1);
        }
      }
    },

    bookAppointment(app_type) {

      let data = {
        user_id: localStorage.getItem('usertoken'),
        appointment_date: this.dateGet,
        appoint_time: this.selectedTime,
        showAppointmentTimeNew: this.showTimeFormate,
        technician_id: this.openPopIdGet,
        customer_id: this.customerId_choose,
        serviceData: this.showSelectedService,
        technicianselect: this.techSelected,
        appointment_type: app_type,
        relatedAppointmentID: this.relatedAppointmentID
      };
      // console.log("bookAppointment", data);
      axios
        .post('/api/appointment', data,
          {
            headers: {
              "Accept": "application/json",
            },
          }
        )
        .then((resp) => {

          if (resp['data']['data']) {
            if (app_type == 'still_in_progress') {
              // this.openModalAddTechNew();
              this.closeModalAddTechNew();
              // this.isModalVisible = true;
              // console.log("helloothis.isModalVisible", this.isModalVisible);
              if (this.relatedAppointmentID == null) {
                this.relatedAppointmentID = resp['data']['data'].id;
              }

            } else {
              this.relatedAppointmentID = null;
              this.isModalVisible = false;
              this.customerNameSelected = '';
              this.customerId_choose = '';
              Swal.fire({
                html: `
                        <div style="display: flex;align-items: center;justify-content: center;">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 15px;">
                            <path fill="currentColor" d="M10 16.172l-4.95-4.95L4.05 10.5l5.95 5.95L20.95 6.95 22.36 8.36 10 16.172z" style="stroke: white;"/>
                          </svg>
                          Appointment has been created
                        </div>
                      `,
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true
              });
            }

            this.appointmentErrormsg = [];
            this.refreshBarGraphData();
            this.hideMod(this.openPopIdGet);
            this.$refs.horizonCalendarAction.takingAction();
            this.selectedTime = '';
            this.openPopIdGet = '';
            this.hideText = true;
            this.hideTextSecond = '';
            this.showSelectedService = [];
            this.techSelected = '';
            this.closedModalAddTechNew();
            this.callBackcategoriesApi();
          }
          else {

            Swal.fire({
              title: 'Oops',
              text: "error",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            });

          }

        })
        .catch(e => {
          this.appointmentErrormsg = e.response.data
        })
    },
    addTechAppointment(app_type) {
      let appointmentId = this.saveApId;
      this.customerId_choose = this.editCustomerId;

      let data = {
        user_id: localStorage.getItem('usertoken'),
        appointment_date: this.dateGet,
        appoint_time: this.appointmentTime,
        technician_id: this.openPopIdGet,
        customer_id: this.editCustomerId,
        serviceData: this.showSelectedService,
        technicianselect: this.editTechnicianId,
        appointment_type: app_type,
        relatedAppointmentID: this.appPoinId,

        showAppointmentTimeNew: this.showTimeFormate,
      };
      // console.log("bookAppointment===", data);
      axios
        .put(`/api/update-add-tech-appointment/${appointmentId}`, data,
          {
            headers: {
              "Accept": "application/json",
            },
          }
        )
        .then((resp) => {

          if (resp['data']['data']) {


            if (app_type == 'still_in_progress') {
              this.hideText = false;
              this.hideTextSecond = true;
              //this.isModalVisible = true;
              this.isOverlayVisible = false;
              this.openModalAddTechNew();
              this.closeModalAddTechNew();

              if (this.relatedAppointmentID == null) {
                this.relatedAppointmentID = resp['data']['data'].id;
                this.customerId_choose = this.editCustomerId;

              }

            } else {
              console.log("if else");
              this.relatedAppointmentID = null;
              this.isModalVisible = false;
              this.customerNameSelected = '';
              this.customerId_choose = '';
              Swal.fire({
                text: "Appointment update successfully!",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true
              });
            }
            this.appointmentErrormsg = [];
            this.refreshBarGraphData();
            this.hideMod(this.openPopIdGet);
            this.$refs.horizonCalendarAction.takingAction();
            this.selectedTime = '';
            this.openPopIdGet = '';

            this.showSelectedService = [];
            this.techSelected = '';
            this.callBackcategoriesApi();
          }
          else {

            Swal.fire({
              title: 'Oops',
              text: "error",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            });
          }
        })
        .catch(e => {
          this.appointmentErrormsg = e.response.data
        })
    },
    changeDate(datePicked) {
      this.dateGet = moment(datePicked).format("YYYY-MM-DD");
      this.refreshBarGraphData();
    },
    technicianIdselected(technicianId) {
      this.techSelected = technicianId;
      // console.log("this.techSelected", this.techSelected);
    },

    updateAppointmentStatus(appointmentID, appointmentStatus) {
      this.showLoader();
      let data = {
        id: appointmentID,
        status: appointmentStatus
      }

      axios
        .post('/api/booking-status', data,
          {
            headers: {
              "Accept": "application/json",
            },
          }
        )
        .then((resp) => {
          if (resp.data.data) {
            Swal.fire({
              text: "Appointment " + resp.data.data.appointment_status + " successfully!",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            });
            this.$refs.horizonCalendarAction.takingAction();
            this.refreshBarGraphData();
            this.toggleOverlay();
          }
        })
        .catch(e => {
          console.log('error', e);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    openEditstatus(element) {
      let el = document.getElementById(element);
      this.editFormData = true;
      if (el) {
        el.style.display = 'block';
      }
    },
    // updateAppointmentStatusCheckOut(data) {
    //   console.log("hollaaaa--------data",data);
    //   axios
    //     .post('/api/booking-status-checkout', data,
    //       {
    //         headers: {
    //           "Accept": "application/json",
    //         },
    //       }
    //     )
    //     .then((resp) => {
    //       if (resp.data.data) {
    //         console.log("hollaaaa--------",resp.data.data);
    //         Swal.fire({
    //           title: 'Good job!',
    //           text: "Appointment " + resp.data.data.appointment_status + " successfully!",
    //           icon: 'success',
    //         });
    //         this.refreshBarGraphData();
    //         this.toggleOverlay();
    //       } else {
    //         Swal.fire({
    //           title: 'Warning',
    //           text: "Appointment remain to be served",
    //           icon: 'warning',
    //         });
    //       }

    //     })
    //     .catch(e => {
    //       console.log(e);
    //       Swal.fire({
    //         title: 'Warning',
    //         text: 'Payment amount field are required',
    //         icon: 'warning',
    //       });
    //       this.refreshBarGraphData();
    //     })
    // },
    callBackcategoriesApi() {
      let data = {
        user_id: localStorage.getItem('usertoken'),
        techID: this.openPopIdGet,
      }
      axios.post('/api/categoryServiceData', data)
        .then((resp) => {
          this.category = resp.data.category;
          if (resp.data.serviceListData[0] == null) {

          } else {
            let dummyCat = {
              id: 0,
              name: 'Recent',
              servicelist: resp.data.serviceListData
            }
            this.category.push(dummyCat);
            this.category.reverse();
          }
        })
    },
    moveAppointmentCheck(condition) {
      if (condition == 'closePopup') {
        this.isMovePopVisible = false;
        this.moveConditionSetActivated = false;
      } else {
        let data = {
          id: this.appointmentIDMove,
          techID: this.openPopIdGet,
          timeUpdate: this.selectedTime,
          appointment_date: this.dateGet
        };

        axios
          .patch('/api/appointment/' + this.appointmentIDMove, data,
            {
              headers: {
                "Accept": "application/json",
              },
            }
          )
          .then((resp) => {
            if (resp.data.data) {
              this.moveConditionSetActivated = false;
              this.isMovePopVisible = false;
              this.refreshBarGraphData();
              Swal.fire({
                text: "Appointment moved successfully!",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true
              });
            }
          })
          .catch(e => {
            console.log('error', e);
          })
      }
    },

    getCoordinateClicked(event, technician_id, timev) {
      if (this.customerId_choose !== "") {
        setTimeout(() => {
          this.selectCustomer(this.customerId_choose);
        }, 1000);
      }
      this.searchValue = '';
      this.isOverlayVisible = false;
      this.openPopIdGet = technician_id;
      this.selectedTime = moment(timev, ["hh:mm a"]).format("HH:mm A");
      this.callBackcategoriesApi();
      if (this.relatedAppointmentID == null) {
        this.customerNameSelected = '';
        this.customerId_choose = '';
      }

      this.currentTechnician = {};
      if (this.moveConditionSetActivated == false) {

        for (let i = 0; i < this.technician.length; i++) {
          if (this.technician[i].technician_id == technician_id) {
            this.currentTechnician = this.technician[i];
            break;
          }
        }
        this.currentTechnician.time = timev + " New Booking";
        // $('#timeData' + technician_id).text(timev + " New Booking");
        let el = document.getElementById('bookingNewCustomer');
        if (el) {
          el.style.display = 'block';
        }
        el.style.position = "absolute";
        el.style.zIndex = '99999';
        el.style.width = '100%';
        el.style.maxWidth = '344px';
        el.style.marginLeft = '13px';
        let spanLeft = event.currentTarget.offsetLeft;
        el.style.left = spanLeft + 'px';
        el.style.top = (event.pageY - 228) + 'px';
        this.showModel(technician_id, this.formatHourMinute(hour, '00'));
        //this.showModel(technician_id);
        this.technicianIdselected(technician_id);
        //this.techNameGet(tech.fname, tech.lname);
        this.techNameGet(this.currentTechnician.fname, this.currentTechnician.lname);
        // el.style.left = (event.pageX - 150) + 'px';
        // el.style.top = (event.pageY - 228) + 'px';
      }
    },
    techNameGet(techname, techlname) {

      this.movetoTechnicianName = techname + ' ' + techlname;
    }
  }
}
</script>

<style scoped>
.theme-btn-newlog {
  font-size: 20px;
  padding: 0px;
}

.theme-btn-newlog svg {
  margin: 0;
  transform: translateY(-2px);
  width: 25px;
  height: 26px;
}

.theme-btn-newlog svg g path {
  fill: var(--colorD);
}

.expandedd {
  width: 60%;
}

.content-one {
  padding: 20px;
  text-align: center;
  font-family: 'Coming Soon', cursive;
  font-size: 16px;
}

button.btn.orange-bar-side.collaped-md {
  transform: rotate(-90deg);
}

button.btn.orange-bar-side.collaped-md {
  width: 150px;
  height: 40px;
}

.sticky-alert {
  position: fixed;
  top: 0;
  background-color: var(--colorB) !important;
  padding: 15px 46px 30px 46px;
  width: 100%;
  max-width: 515px;
  height: auto;
  margin: 0 auto;
  text-align: center;
  line-height: 100px;
  border: 1px solid #ccc;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 99999;
  left: 30%;
  justify-content: center !important;
  display: flex;
  color: var(--colorD) !important;
  font-size: 17px;
  font-weight: 700;
}

.alert-content {
  display: inline-block;
  width: 100%;
}

.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: var(--colorD);
}

.close:hover {
  color: var(--colorC);

}

.appt-no-display {
  margin-right: 10px;
  color: var(--colorD);

}

.color-apt {
  color: var(--colorD);
}
</style>