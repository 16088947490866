<template id="sellEgiftCardModal">
    <div class="gift-voucher__content mb-3">
        Sell Giftcard
    </div>
    <div v-if="showAfterSellGift">
        <div class="gift-voucherheader">
            <img src="images/gift-card.jpg" alt="LUV Nails Logo" class="gift-voucher__header__logo" />
            <div class="EgiftCardInput">
                <span class="font-sizeW-18 my-2">{{ giftcode }}</span>
                <div class="number">
                    <span class="minus1" @click="updateQuantity(-5)">-</span>
                    <input class="moneyinput1" v-model="formattedPrice" name="dollars" id="dollars" type="text" />
                    <span class="plus1" @click="updateQuantity(5)">+</span>
                </div>
            </div>
        </div>
        <div class="searchgift-card pt-4" v-if="!showModalCustomerAddNew" id="searchgift-card">
            <div v-if="customerNameSelected == '' ? true : false" class="form-group search-group mb-1">
                <label class="input-label">Buyer Info</label>
                <input type="text" class="form-control search_customer_mobile" placeholder="Search customer"
                    v-model="searchValue" @keyup="getData">
                <button class="search">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                        <path id="Path_465" data-name="Path 465"
                            d="M-6225.9-7073.878a.808.808,0,0,1-.574-.25c-.8-.787-1.6-1.581-2.38-2.388a.757.757,0,0,1-.235-.516c.064-.388-.156-.578-.39-.778l-.028-.025-.042.022a5.687,5.687,0,0,1-3.775,1.423,5.836,5.836,0,0,1-4.49-2.112,5.777,5.777,0,0,1,.487-7.788,5.587,5.587,0,0,1,3.948-1.589,5.815,5.815,0,0,1,3.729,1.349,5.749,5.749,0,0,1,2.015,3.921,5.91,5.91,0,0,1-1.457,4.355l.1.088a2.151,2.151,0,0,0,.255.206.489.489,0,0,0,.236.084,1.447,1.447,0,0,1,.2-.018.786.786,0,0,1,.563.241c.806.8,1.61,1.6,2.4,2.406a.792.792,0,0,1,.238.574.8.8,0,0,1-.256.565A.777.777,0,0,1-6225.9-7073.878Zm-7.473-13.047a4.772,4.772,0,0,0-4.773,4.748,4.826,4.826,0,0,0,1.392,3.426,4.751,4.751,0,0,0,3.4,1.407,4.794,4.794,0,0,0,4.787-4.776,4.788,4.788,0,0,0-1.4-3.405,4.765,4.765,0,0,0-3.4-1.4l-.008-.2Z"
                            transform="translate(6239.094 7087.878)" fill="#97bfb2"></path>
                    </svg>
                </button>
                <button id="icon_changeO" class="icon_change" v-if="removeIcoButton" @click="removeCustomerSearch()">
                    <svg data-v-e73c3a1a="" version="1.0" id="katman_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1436 1054"
                        xml:space="preserve">
                        <path data-v-e73c3a1a=""
                            d="M718.5,453.8l224-224.3c20.4-20.4,53.3-20.4,73.6,0c20.4,20.4,20.4,53.3,0,73.6l-224,224.6l224,224
                          c20.4,20.4,20.4,53.3,0,73.6c-20.4,20.4-53.3,20.4-73.6,0l-224-224l-224.6,224c-20.4,20.4-53.3,20.4-73.6,0
                          c-20.4-20.4-20.4-53.3,0-73.6l224-224L420.4,303.2c-20.5-20.4-20.5-53.3-0.1-73.6s53.3-20.4,73.6,0l224.6,224V453.8z">
                        </path>
                    </svg>
                </button>
            </div>
            <div class="some_o">
                <ul class="bg_white">
                    <li class="bg-white px-3 py-1" v-for="cust in customer" :key="cust.id"
                        @click="selectCustomer(cust.id)" :style="{ border: cust ? '1px solid #eaeaea;' : '' }">
                        <a class="first-ch" href="javascript:void(0);" :class="{ 'selected': isSelected(cust.id) }">
                            <label class="mb-0">
                                <div class="customer-name-new">
                                    <h4 class="heading-20-white">Customer</h4>
                                    <p>
                                        <span class="dummy-img mb-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.201" height="16.859"
                                                viewBox="0 0 15.201 16.859">
                                                <g id="Group_1632" data-name="Group 1632"
                                                    transform="translate(4923.887 6021.4)">
                                                    <g id="Group_1631" data-name="Group 1631">
                                                        <path id="Path_1413" data-name="Path 1413"
                                                            d="M-4916.286-6012.585a4.412,4.412,0,0,1-4.407-4.407,4.413,4.413,0,0,1,4.407-4.408,4.413,4.413,0,0,1,4.407,4.408A4.412,4.412,0,0,1-4916.286-6012.585Zm0-8.022a3.619,3.619,0,0,0-3.616,3.615,3.62,3.62,0,0,0,3.616,3.615,3.62,3.62,0,0,0,3.616-3.615A3.619,3.619,0,0,0-4916.286-6020.607Z"
                                                            fill="#d5a948" />
                                                        <path id="Path_1414" data-name="Path 1414"
                                                            d="M-4923.887-6004.541l.038-2.617a4.866,4.866,0,0,1,4.861-4.86h.188a3.842,3.842,0,0,0,2.532,1,3.605,3.605,0,0,0,2.392-.9l.16-.089.171-.011a4.867,4.867,0,0,1,4.859,4.862v2.615Zm14.376-.792.035-1.825a4.083,4.083,0,0,0-4.02-4.067,4.28,4.28,0,0,1-2.772,1,4.4,4.4,0,0,1-2.8-1,4.084,4.084,0,0,0-3.992,4.069v1.787Z"
                                                            fill="#d5a948" />
                                                    </g>
                                                </g>
                                            </svg>
                                            {{ cust.fname }} {{ cust.lname }}
                                        </span>
                                    </p>
                                </div>
                            </label>
                            <span class="new">New</span>
                        </a>
                    </li>
                    <li class="bgcolor-yellow px-3 py-1" v-if="addCustomerShowBtn"
                        style="border: 1px solid var(--colorB) !important;">
                        <div class="text-start">
                            <a class="theme-btn-newlog yellow-btn text-white" @click="openModalAddCustomer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                    class="bi bi-plus" viewBox="0 0 16 16">
                                    <path
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                                        fill="white">
                                    </path>
                                </svg>
                                New customers
                            </a>
                        </div>
                    </li>
                </ul>
                <div v-if="customerNameSelected == '' ? false : true" class="form-group">
                    <label class="input-label">Buyer Info</label>
                    <div class="last-activity-list mt-2">
                        <div class="transaction box egift-box d-flex radius-8" style="height: 80px;">
                            <div class="d-flex details">
                                <p>Customer </p>
                                <div class="d-flex justify-content-between my-1">
                                    <p>{{ customerNameSelected }}</p>
                                </div>
                            </div>
                            <div class="">
                                <p class="mt-0">Phone</p>
                                <p class="mt-1">{{ selectedCustomerPhone }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="dummy-md-one gift-remove-name">
                        <div class="customer-name-new">
                            <p>
                                <a href="#" class="float-right" @click="removeCustomerselect" id="glassAnimals">
                                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 15.25 15.25" width="24" height="24">
                                        <path class="cls-1"
                                            d="m1,15.25c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41L13.54.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L1.71,14.95c-.2.2-.45.29-.71.29Z" />
                                        <path class="cls-1"
                                            d="m14.25,15.25c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1.68.29.29S1.32-.1,1.71.29l13.25,13.25c.39.39.39,1.02,0,1.41-.2.2-.45.29-.71.29Z" />
                                    </svg>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!showModalCustomerAddNew" class="modal-btns d-flex ">
            <span class="theme-btn white-btn" @click="removeCustomerselect">Back</span>
            <button type="submit" class="theme-btn yellow-btn" @click="submitFormAfterNewCustomer">Sell</button>
        </div>
    </div>
    <div v-if="showModalCustomerAddNew">
        <label class="gift-voucher__content my-3" style="font-weight: 600;">New Customer</label>
        <form v-on:submit.prevent="save_customer">
            <div class="new-customer-main d-flex flex-column">
                <div class="new-customer-form">
                    <div class="form-group-50">
                        <input type="text" class="form-control" name="fname" placeholder="First Name"
                            v-model="form.fname" autocomplete="off">
                    </div>
                    <div class="form-group-50">
                        <input type="text" class="form-control" name="lname" placeholder="Last Name"
                            v-model="form.lname" autocomplete="off">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" name="phone" placeholder="Phone" v-model="form.phone"
                            autocomplete="off">
                    </div>
                </div>

                <div class="modal-btns d-flex ">
                    <span class="theme-btn white-btn" @click="showModalCustomerAddNew = false">Cancel</span>
                    <button type="submit" class="theme-btn yellow-btn">Done</button>
                </div>
            </div>
        </form>
    </div>
    <div v-if="AfterSelectedCustomer">
        <div class="gift-voucherheader" style="height: 250px;">
            <img src="images/gift-card.jpg" alt="LUV Nails Logo" class="gift-voucher__header__logo" />
            <div class="last-activity-list">
                <div class="transaction box egift-box d-flex" style="height: 80px;">
                    <div class="d-flex details">
                        <p>{{ customerNameSelected }} </p>
                        <div class="d-flex justify-content-between my-1">
                            <p class="color-yellow">{{ giftcode }}</p>
                        </div>
                    </div>
                    <div class="amount">
                        <p class="my-1" style="margin-top: 0px !important;">Balance</p>
                        <p class="mt-1">$ {{ Balance }}.00</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="last-activity-list mt-4">
            <div class="transaction box egift-box d-flex radius-8 active" style="height: 75px;">
                <div class="d-flex details">
                    <span>Buyer </span>
                    <p>{{ customerNameSelected }}</p>
                </div>
                <div class="">
                    <span class="">Phone</span>
                    <p>5645645646</p>
                </div>
            </div>
        </div>

        <div class="last-activity-list mt-3">
            <div class="transaction box egift-box d-flex radius-5" style="height: 90px; padding: 8px !important;">
                <div class="d-flex details ">
                    <img src="images/eGift-1.jpg" alt="LUV Nails Logo" class="gift-final-sec" />
                </div>
                <div class="d-flex details mr-2">
                    <span>Dec 15, 2023</span>
                    <p class="pt-1">Toan Thang</p>
                    <span class="pt-1">Redeem by</span>
                </div>
                <div class="amount pr-1" style="margin-top: 20px;">
                    <p class="float-right">-25.00</p>
                    <p>Minnie Kong</p>
                </div>
            </div>
        </div>
        <div class="modal-btns d-flex ">
            <span v-if="!Balance" class="theme-btn white-btn" @click="removeCustomerselect">Back</span>
            <button v-if="!Balance" type="submit" class="theme-btn yellow-btn" @click="resetThisGift">Reset
                this Gift</button>
            <span v-else class="theme-btn white-btn center mx-auto" @click="removeCustomerselect">Back</span>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
    props: {
        giftcodeData: String,
        giftcardObject: String,
    },
    emits: ['close', 'reloadCards'],
    data() {
        return {
            category: [],
            searchValue: '',
            customer: '',
            selectedCustomer: [],
            customerNameSelected: '',
            customerNameSelectedupdate: '',
            AfterSelectedCustomer: false,
            form: {
                fname: '',
                lname: '',
                phone: '',
            },
            customerErrormsg: [],
            addCustomerShowBtn: false,
            customerId_choose: '',
            removeIcoButton: true,
            showModalCustomerAddNew: false,
            showAfterSellGift: true,
            selectedCustomerPhone: '',
            giftcode: '',
            ScannedGift: {},
            user_id: '',
            month: 'all_time',
            giftCardPrice: 0,
        }
    },

    computed: {
        formattedPrice: {
            get() {
                return `$${this.giftCardPrice}`;
            },
            set(value) {
                const numericValue = parseFloat(value.replace(/[^\d.]/g, '')) || 0;
                this.giftCardPrice = Math.max(numericValue, 0);
            }
        }
    },
    mounted() {
        this.user_id = localStorage.getItem('usertoken')
        this.giftcode = this.giftcodeData
        this.ScannedGift = this.giftcardObject

    },
    methods: {

        updateQuantity(change) {
            const currentPrice = parseFloat(this.giftCardPrice) || 0;
            this.giftCardPrice = Math.max(currentPrice + change, 0);
        },

        removeCustomerselect() {
            this.$emit('close');
            this.customerNameSelected = '';
            this.customerId_choose = '';
            this.AfterSelectedCustomer = false;
            this.showAfterSellGift = true;
        },
        openGiftcollection() {
            this.showAfterSellGift = !this.showAfterSellGift;
        },
        resetThisGift() {
            this.customerNameSelected = '';
            this.customerId_choose = '';
            this.giftCardPrice = 0
            this.showAfterSellGift = true;
            this.AfterSelectedCustomer = false;
        },
        submitFormAfterNewCustomer() {
            if (this.customerId_choose) {
                const giftSellInfo = {
                    gift_id: this.ScannedGift.id,
                    user_id: this.user_id,
                    buyer_id: this.customerId_choose,
                    amount: this.giftCardPrice
                }
                axios.post("api/add-buyer-to-gift-card", giftSellInfo)
                    .then((response) => {
                        if (response.status == 200) {
                            this.AfterSelectedCustomer = false;
                            this.showAfterSellGift = false;
                            this.Balance = response.data.data.remaining_amount
                            Swal.fire({
                                text: "You have successfully added the customer to this card.",
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true
                            });
                            this.$emit('reloadCards', this.month);
                            this.removeCustomerselect()
                        }
                    })
                    .catch((error) => console.log(error))
            }

        },
        openModal() {
            this.showModalone = true;
            this.customerErrormsg = [];
            this.customer = '';
            this.searchValue = '';
        },
        openModalAddCustomer() {
            this.showModalCustomerAddNew = !this.showModalCustomerAddNew;
            this.customerErrormsg = [];
            this.customer = '';
            this.searchValue = '';
        },
        removeCustomerSearch() {
            this.removeIcoButton = false;
            this.customer = '';
            this.searchValue = '';
        },
        selectCustomer(cust) {
            if (this.isSelected(cust)) {
                const index = this.selectedCustomer.indexOf(cust);
                if (index !== -1) {
                    this.selectedCustomer.splice(index, 1);
                }
            } else {
                let customerChoose = this.customer.find(customer => {
                    if (customer.id === cust) {
                        return customer;
                    }
                });
                this.customerNameSelected = customerChoose.fname + ' ' + customerChoose.lname;
                this.selectedCustomerPhone = customerChoose.phone;
                this.selectedCustomer = [];
                this.addCustomerShowBtn = false;
                this.selectedCustomer.push(cust);
                setTimeout(() => {
                    this.customer = '';
                    this.searchValue = '';
                    //this.openModalAddServiceNew();
                }, 1000);

            }
            // this.selectedCustomer = cust;
            this.customerId_choose = cust;
        },
        isSelected(cust) {
            return this.selectedCustomer.includes(cust);
        },
        removeCustomerSearch() {
            this.removeIcoButton = false;
            this.customer = '';
            this.searchValue = '';
        },
        getData() {
            this.removeIcoButton = true;
            this.addCustomerShowBtn = false;
            this.selectedCustomer = [];
            if (this.searchValue.length > 1) {

                this.addCustomerShowBtn = true;
                axios.get('/api/searchuser?user_id=' + localStorage.getItem('usertoken') + '&number=' + this.searchValue)
                    .then((resp) => {
                        this.customer = resp.data.customer;
                        this.$nextTick(() => {
                            const iconElement = document.querySelector('.icon_change');
                            if (iconElement) {
                                iconElement.classList.add('new-Icon-change');
                            }
                        });
                    })
            }
            else {
                this.customer = '';
            }

        },
        sellGiftHome() {
            this.customerNameSelected = '';
            this.customerId_choose = '';
            this.giftCardPrice = 0
            this.showAfterSellGift = true
            this.AfterSelectedCustomer = false;
            $('#sellEgiftCardModal').modal('hide');

        },

        save_customer() {
            axios
                .post('/api/customer', {
                    fname: this.form.fname,
                    lname: this.form.lname,
                    phone: this.form.phone,
                    user_id: localStorage.getItem('usertoken')
                }, {
                    headers: {
                        "Accept": "application/json",
                    },
                })
                .then((resp) => {
                    this.form.fname = '';
                    this.form.lname = '';
                    this.form.phone = '';
                    this.notifmsg = [];

                    if (resp.data.customer) {
                        Swal.fire({
                            text: "Customer Created Successfully!",
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        });
                        this.showModalCustomerAddNew = false;

                    } else {
                        Swal.fire({
                            text: "Error",
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        });
                        this.showModalCustomerAddNew = true;
                    }
                })
                .catch(e => {
                    this.notifmsg = e.response.data;
                    this.showModalCustomerAddNew = true;
                    Swal.fire({
                        text: 'Please fill out the form before submitting.',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    });
                });
        },

    },
}
</script>