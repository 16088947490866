<template>

    <div class="appointment-list appointment-list-2" style="margin-bottom: 20px;" v-for="(tech, index) in technician" :key="tech.technician_id">
        <div class="user-img user-img-img" @click="getTechIdAddTechnician(tech.technician_id)">
            <img :src="tech.tech_imageUrl" alt="Technician">
        </div>
        <span class="numbers green-bg">115’ <span></span></span>
        <div class="collapse data-collapse" :id="'userData' + tech.technician_id">
            <div class="label-on">
                <p class="">{{ tech.fname }} {{ tech.lname }}</p>

                <div class="d-flex justify-content-around test">
                    <!-- <div class="appt-no-display color-apt">Appt {{ totalAppointmentRecord[index].totalAppointment }}
                    </div> -->
                    <!-- <div class="ml-0 color-apt">Earned {{ totalAppointmentRecord[index].earningTotal }} $</div> -->
                </div>
                <a href="#" class="close" @click="hideMod(tech.id)" id="glassAnimals">
                    <svg version="1.0" id="katman_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1436 1054"
                        style="enable-background:new 0 0 1436 1054;" xml:space="preserve">
                        <path
                            d="M718.5,453.8l224-224.3c20.4-20.4,53.3-20.4,73.6,0c20.4,20.4,20.4,53.3,0,73.6l-224,224.6l224,224
                      c20.4,20.4,20.4,53.3,0,73.6c-20.4,20.4-53.3,20.4-73.6,0l-224-224l-224.6,224c-20.4,20.4-53.3,20.4-73.6,0
                      c-20.4-20.4-20.4-53.3,0-73.6l224-224L420.4,303.2c-20.5-20.4-20.5-53.3-0.1-73.6s53.3-20.4,73.6,0l224.6,224V453.8z" />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        technician: {
            type: Array,
            default: () => []
        }
    }
};
</script>
<style>
/* .appointment-list-one .user-img img{
    width: 65px;
    height: 65px;
    padding: 3px;
    border-radius: 50%;
    border: 1px solid var(--colorA) !important;
    background: #fff;
    position: relative;
    z-index: 6;
}
.appointment-list-one {
    display: flex;
} */
 /* CSS */
.appointment-list-2 {
  margin-bottom: 20px !important; 
  position: relative !important; 
}

.user-img-img {
  position: sticky !important;
  top: 0!important; 
  z-index: 10 !important; 
  background-color: white; 
  padding: 10px !important; 
}

</style>