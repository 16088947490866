<template>
    <div class="modal fade common-modal callScreenTWO" id="modelOpenSecond" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close-svg" data-bs-dismiss="modal" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="7.905" height="7.905" viewBox="0 0 7.905 7.905">
                            <g id="Group_1469" data-name="Group 1469" transform="translate(-1480.904 -467.983)">
                                <line id="Line_340" data-name="Line 340" x1="5.784" y2="5.784"
                                    transform="translate(1481.965 469.043)" fill="none" stroke="#d5a948"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <line id="Line_341" data-name="Line 341" x1="5.784" y1="5.784"
                                    transform="translate(1481.965 469.043)" fill="none" stroke="#d5a948"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                            </g>
                        </svg>
                    </button>
                    <div class="heading">
                        <h3>
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 27.99 28">
                                <path class="cls-1"
                                    d="M861.06,566.12a10.38,10.38,0,0,1-3.7-.6c-.83-.29-1.65-.6-2.38-.88a18.84,18.84,0,0,1-5.08-2.93,37.75,37.75,0,0,1-5.19-5.12,23.36,23.36,0,0,1-4.13-8,8.76,8.76,0,0,1-.49-3.34,9,9,0,0,1,2.55-5.63,3.09,3.09,0,0,1,1.37-.86c.73-.21,1.46-.37,2.15-.51a2.74,2.74,0,0,1,.66-.09,2.34,2.34,0,0,1,2.07,1.29l.16.29c.29.53.55,1,.81,1.45l1.85,3.12a2.38,2.38,0,0,1-.31,3c-.38.39-.78.76-1.18,1.12l-1.19,1,.27.45c.52.88,1,1.71,1.57,2.49a14.79,14.79,0,0,0,4.63,4.1c.37.22.75.43,1.18.66l.08,0,1.15-1.29a12.5,12.5,0,0,1,.87-1,2.55,2.55,0,0,1,1.78-.84,3,3,0,0,1,1.54.49l4.08,2.39.72.41a2.26,2.26,0,0,1,1.16,2.19,8.87,8.87,0,0,1-.71,2.95,3.18,3.18,0,0,1-.78,1.09,9,9,0,0,1-5.49,2.51ZM846.82,540a1.19,1.19,0,0,0-.26,0c-.68.14-1.36.28-2,.47a1.38,1.38,0,0,0-.54.36,6.63,6.63,0,0,0-1.65,7.13,21.58,21.58,0,0,0,3.79,7.41,34.5,34.5,0,0,0,4.92,4.86,16.63,16.63,0,0,0,4.43,2.6c.89.34,1.69.64,2.49.92a8.33,8.33,0,0,0,3,.5,7.15,7.15,0,0,0,4.3-2,1.45,1.45,0,0,0,.33-.46,7.11,7.11,0,0,0,.56-2.34A.42.42,0,0,0,866,559l-.71-.41-4.1-2.4a1.27,1.27,0,0,0-.58-.23c-.16,0-.37.18-.52.32a10.39,10.39,0,0,0-.72.78l-.76.87c-.28.31-.5.57-.73.81a1.27,1.27,0,0,1-1,.45l-.38-.07-.26-.11c-.64-.34-1.17-.63-1.69-.94a16.44,16.44,0,0,1-5.2-4.62c-.6-.85-1.16-1.78-1.64-2.6l-.5-.84a1.29,1.29,0,0,1,.29-1.71l.73-.64L849,547c.42-.38.78-.7,1.11-1a.56.56,0,0,0,0-.74l-1.86-3.13c-.28-.5-.56-1-.83-1.5l-.16-.28A.51.51,0,0,0,846.82,540Z"
                                    transform="translate(-840.07 -538.12)" />
                            </svg>
                            Phone
                        </h3>
                    </div>
                    <h2 class="heading-20-white mb-3">Customer connection</h2>
                    <div class="">
                        <div class="">
                            <div class="callinfo">
                                <div class="card call-card radius-10">
                                    <div class="card-body text-center">
                                        <h4 class="card-title one" :title="custName">{{ custName }}</h4>
                                        <div class="d-flex justify-content-between mt-3 py-1 px-2 bgcolor-yellow color-D radius-5"
                                            id="addBgColorSecond">
                                            <span class="font-sizeW-18"
                                                style=" display: block; font-weight: 100; margin-right: 0;"
                                                id="NewcallCalling">Calling</span>
                                            <span class="font-sizeW-18"
                                                style=" display: block; font-weight: 100; margin-left: 0;"
                                                id="NewcallDuration">00:00:00</span>
                                        </div>
                                        <div class="call-buttons mt-5 pt-5">
                                            <button class="btn btn-circle" id="outboundHangup"
                                                data-bs-toggle="modal" data-bs-target="#chatModal" style="background-color: var(--colorB);">
                                                <svg width="35px" height="25px" version="1.1" id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                                    xml:space="preserve">
                                                    <g>
                                                        <path
                                                            d="M478.1,270.1c3.5,24.4,5.8,57.7-5.1,70.5c-18.1,21.1-132.6,21.1-132.6-21.1c0-21.3,18.8-35.2,0.8-56.3
                                                            C323.4,242.4,291.5,242,256,242s-67.3,0.3-85.1,21.1c-18.1,21.1,0.8,35.1,0.8,56.3c0,42.2-114.5,42.2-132.6,21.1
                                                            c-10.9-12.8-8.6-46.1-5.1-70.5c2.7-16.3,9.5-33.8,31.4-56.3l0,0c32.9-30.7,82.6-55.7,188.9-56.3l0,0c0.6,0,1.2,0,1.8,0
                                                            c0.6,0,1.2,0,1.8,0l0,0c106.3,0.5,156,25.6,188.9,56.3l0,0C468.6,236.2,475.4,253.8,478.1,270.1L478.1,270.1z" />
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        custName: String
    },
}
</script>