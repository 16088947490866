import { createRouter, createWebHistory } from 'vue-router'

import Login from './components/Login.vue'
import Register from './components/Register.vue'
import Dashboard from './components/Dashboard.vue'
import NavBar from './components/NavBar.vue'
import HorizonCalendar from './components/HorizonCalendar.vue'
import EgiftCard from './components/EgiftCard.vue'
import PlivoModel from './components/PlivoModel.vue'

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,

    },

    {
        path: '/register',
        name: 'register',
        component: Register,

    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,

    },

    {
        path: '/page3',
        name: 'page3',
        component: HorizonCalendar,
    },
    {
        path: '/page4',
        name: 'page4',
        component: NavBar,
    },

    {
        path: '/egiftCard',
        name: 'egiftCard',
        component: EgiftCard,
    },
    {
        path: '/plivoCall',
        name: 'plivoCall',
        component: PlivoModel,
    },

];


export default createRouter({
    history: createWebHistory(),
    routes
})
