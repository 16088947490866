<template>
    <div class="working-time mt-3">
                                <ul class="days text-center nav nav-tabs">
                                    <li><a data-toggle="tab" href="#mon-edit" class="active">Mon</a></li>
                                    <li><a data-toggle="tab" href="#tue-edit">Tue</a></li>
                                    <li><a data-toggle="tab" href="#wed-edit">Wed</a></li>
                                    <li><a data-toggle="tab" href="#thu-edit">Thu</a></li>
                                    <li><a data-toggle="tab" href="#fri-edit">Fri</a></li>
                                    <li><a data-toggle="tab" href="#sat-edit">Sat</a></li>
                                    <li><a data-toggle="tab" href="#sun-edit">Sun</a></li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane fade in active show" id="mon-edit">
                                        <div class="hours mt-2 invisible-checkboxes">

                                            <input type="checkbox" name="rGroup" id="r1-edit" value="mon|01:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r1-edit">01:00</label>

                                            <input type="checkbox" checked name="rGroup" id="r2-edit" value="mon|01:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r2-edit">01:30</label>

                                            <input type="checkbox" name="rGroup" id="r3-edit" value="mon|02:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r3-edit">02:00</label>

                                            <input type="checkbox" name="rGroup" id="r4-edit" value="mon|02:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r4-edit">02:30</label>

                                            <input type="checkbox" name="rGroup" id="r5-edit" value="mon|03:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r5-edit">03:00</label>

                                            <input type="checkbox" name="rGroup" id="r6-edit" value="mon|03:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r6-edit">03:30</label>

                                            <input type="checkbox" name="rGroup" id="r7-edit" value="mon|04:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r7-edit">04:00</label>

                                            <input type="checkbox" name="rGroup" id="r8-edit" value="mon|04:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r8-edit">04:30</label>

                                            <input type="checkbox" name="rGroup" id="r9-edit" value="mon|05:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r9-edit">05:00</label>

                                            <input type="checkbox" name="rGroup" id="r10-edit" value="mon|05:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r10-edit">05:30</label>

                                            <input type="checkbox" name="rGroup" id="r11-edit" value="mon|06:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r11-edit">06:00</label>

                                            <input type="checkbox" name="rGroup" id="r12-edit" value="mon|06:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r12-edit">06:30</label>

                                            <input type="checkbox" name="rGroup" id="r13-edit" value="mon|07:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r13-edit">07:00</label>

                                            <input type="checkbox" name="rGroup" id="r14-edit" value="mon|07:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r14-edit">07:30</label>

                                            <input type="checkbox" name="rGroup" id="r15-edit" value="mon|08:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r15-edit">08:00</label>

                                            <input type="checkbox" name="rGroup" id="r16-edit" value="mon|08:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r16-edit">08:30</label>

                                            <input type="checkbox" name="rGroup" id="r17-edit" value="mon|09:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r17-edit">09:00</label>

                                            <input type="checkbox" name="rGroup" id="r18-edit" value="mon|09:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r18-edit">09:30</label>

                                            <input type="checkbox" name="rGroup" id="r19-edit" value="mon|10:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r19-edit">10:00</label>

                                            <input type="checkbox" name="rGroup" id="r20-edit" value="mon|10:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r20-edit">10:30</label>

                                            <input type="checkbox" name="rGroup" id="r21-edit" value="mon|11:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r21-edit">11:00</label>

                                            <input type="checkbox" name="rGroup" id="r22-edit" value="mon|11:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r22-edit">11:30</label>

                                            <input type="checkbox" name="rGroup" id="r23-edit" value="mon|12:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r23-edit">12:00</label>

                                            <input type="checkbox" name="rGroup" id="r24-edit" value="mon|12:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r24-edit">12:30</label>

                                            <input type="checkbox" name="rGroup" id="r25-edit" value="mon|13:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r25-edit">13:00</label>

                                            <input type="checkbox" name="rGroup" id="r26-edit" value="mon|13:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r26">13:30</label>

                                            <input type="checkbox" name="rGroup" id="r27-edit" value="mon|14:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r27-edit">14:00</label>

                                            <input type="checkbox" name="rGroup" id="r28-edit" value="mon|14:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r28-edit">14:30</label>

                                            <input type="checkbox" name="rGroup" id="r29-edit" value="mon|15:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r29-edit">15:00</label>

                                            <input type="checkbox" name="rGroup" id="r30-edit" value="mon|15:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r30-edit">15:30</label>

                                            <input type="checkbox" name="rGroup" id="r31-edit" value="mon|16:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r31-edit">16:00</label>

                                            <input type="checkbox" name="rGroup" id="r32-edit" value="mon|16:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r32-edit">16:30</label>

                                            <input type="checkbox" name="rGroup" id="r33-edit" value="mon|17:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r33-edit">17:00</label>

                                            <input type="checkbox" name="rGroup" id="r34-edit" value="mon|17:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r34-edit">17:30</label>

                                            <input type="checkbox" name="rGroup" id="r35-edit" value="mon|18:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r35-edit">18:00</label>

                                            <input type="checkbox" name="rGroup" id="r36-edit" value="mon|18:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r36-edit">18:30</label>

                                            <input type="checkbox" name="rGroup" id="r37-edit" value="mon|19:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r37-edit">19:00</label>

                                            <input type="checkbox" name="rGroup" id="r38-edit" value="mon|19:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r38-edit">19:30</label>

                                            <input type="checkbox" name="rGroup" id="r39-edit" value="mon|20:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r39-edit">20:00</label>

                                            <input type="checkbox" name="rGroup" id="r40-edit" value="mon|20:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r40-edit">20:30</label>

                                            <input type="checkbox" name="rGroup" id="r41-edit" value="mon|21:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r41-edit">21:00</label>

                                            <input type="checkbox" name="rGroup" id="r42-edit" value="mon|21:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r42-edit">21:30</label>

                                            <input type="checkbox" name="rGroup" id="r43-edit" value="mon|22:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r43-edit">22:00</label>

                                            <input type="checkbox" name="rGroup" id="r44-edit" value="mon|22:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r44-edit">22:30</label>

                                            <input type="checkbox" name="rGroup" id="r45-edit" value="mon|23:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r45-edit">23:00</label>

                                            <input type="checkbox" name="rGroup" id="r46-edit" value="mon|23:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r46-edit">23:30</label>

                                            <input type="checkbox" name="rGroup" id="r47-edit" value="mon|24:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r47-edit">24:00</label>

                                            <input type="checkbox" name="rGroup" id="r48-edit" value="mon|24:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r48-edit">24:30</label>

                                        </div>
                                    </div>
                                    <div class="tab-pane fade " id="tue-edit">
                                        <div class="hours mt-2 invisible-checkboxes">
                                            <input type="checkbox" name="rGroup" id="r01-edit" value="tue|01:00:00"
                                                >
                                            <label class="checkbox-alias" for="r01-edit">01:00</label>

                                            <input type="checkbox" name="rGroup" id="r02-edit" value="tue|01:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r02-edit">01:30</label>

                                            <input type="checkbox" name="rGroup" id="r03-edit" value="tue|02:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r03-edit">02:00</label>

                                            <input type="checkbox" name="rGroup" id="r04-edit" value="tue|02:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r04-edit">02:30</label>

                                            <input type="checkbox" name="rGroup" id="r05-edit" value="tue|03:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r05-edit">03:00</label>

                                            <input type="checkbox" name="rGroup" id="r06-edit" value="tue|03:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r06-edit">03:30</label>

                                            <input type="checkbox" name="rGroup" id="r07-edit" value="tue|04:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r07-edit">04:00</label>

                                            <input type="checkbox" name="rGroup" id="r08-edit" value="tue|04:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r08-edit">04:30</label>

                                            <input type="checkbox" name="rGroup" id="r09-edit" value="tue|05:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r09-edit">05:00</label>

                                            <input type="checkbox" name="rGroup" id="r010-edit" value="tue|05:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r010-edit">05:30</label>

                                            <input type="checkbox" name="rGroup" id="r011-edit" value="tue|06:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r011-edit">06:00</label>

                                            <input type="checkbox" name="rGroup" id="r012-edit" value="tue|06:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r012-edit">06:30</label>

                                            <input type="checkbox" name="rGroup" id="r013-edit" value="tue|07:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r013-edit">07:00</label>

                                            <input type="checkbox" name="rGroup" id="r014-edit" value="tue|07:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r014-edit">07:30</label>

                                            <input type="checkbox" name="rGroup" id="r015-edit" value="tue|08:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r015-edit">08:00</label>

                                            <input type="checkbox" name="rGroup" id="r016-edit" value="tue|08:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r016-edit">08:30</label>

                                            <input type="checkbox" name="rGroup" id="r017-edit" value="tue|09:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r017-edit">09:00</label>

                                            <input type="checkbox" name="rGroup" id="r018-edit" value="tue|09:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r018-edit">09:30</label>

                                            <input type="checkbox" name="rGroup" id="r019-edit" value="tue|10:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r019-edit">10:00</label>

                                            <input type="checkbox" name="rGroup" id="r020-edit" value="tue|10:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r020-edit">10:30</label>

                                            <input type="checkbox" name="rGroup" id="r021-edit" value="tue|11:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r021-edit">11:00</label>

                                            <input type="checkbox" name="rGroup" id="r022-edit" value="tue|11:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r022-edit">11:30</label>

                                            <input type="checkbox" name="rGroup" id="r023-edit" value="tue|12:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r023-edit">12:00</label>

                                            <input type="checkbox" name="rGroup" id="r024-edit" value="tue|12:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r024-edit">12:30</label>

                                            <input type="checkbox" name="rGroup" id="r025-edit" value="tue|13:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r025-edit">13:00</label>

                                            <input type="checkbox" name="rGroup" id="r026-edit" value="tue|13:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r026-edit">13:30</label>

                                            <input type="checkbox" name="rGroup" id="r027-edit" value="tue|14:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r027-edit">14:00</label>

                                            <input type="checkbox" name="rGroup" id="r028-edit" value="tue|14:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r028-edit">14:30</label>

                                            <input type="checkbox" name="rGroup" id="r029-edit" value="tue|15:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r029-edit">15:00</label>

                                            <input type="checkbox" name="rGroup" id="r030-edit" value="tue|15:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r030-edit">15:30</label>

                                            <input type="checkbox" name="rGroup" id="r031-edit" value="tue|16:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r031-edit">16:00</label>

                                            <input type="checkbox" name="rGroup" id="r032-edit" value="tue|16:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r032-edit">16:30</label>

                                            <input type="checkbox" name="rGroup" id="r033-edit" value="tue|17:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r033-edit">17:00</label>

                                            <input type="checkbox" name="rGroup" id="r034-edit" value="tue|17:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r034-edit">17:30</label>

                                            <input type="checkbox" name="rGroup" id="r035-edit" value="tue|18:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r035-edit">18:00</label>

                                            <input type="checkbox" name="rGroup" id="r036-edit" value="tue|18:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r036-edit">18:30</label>

                                            <input type="checkbox" name="rGroup" id="r037-edit" value="tue|19:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r037-edit">19:00</label>

                                            <input type="checkbox" name="rGroup" id="r038-edit" value="tue|19:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r038-edit">19:30</label>

                                            <input type="checkbox" name="rGroup" id="r039-edit" value="tue|20:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r039-edit">20:00</label>

                                            <input type="checkbox" name="rGroup" id="r040-edit" value="tue|20:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r040-edit">20:30</label>

                                            <input type="checkbox" name="rGroup" id="r041-edit" value="tue|21:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r041-edit">21:00</label>

                                            <input type="checkbox" name="rGroup" id="r042-edit" value="tue|21:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r042-edit">21:30</label>

                                            <input type="checkbox" name="rGroup" id="r043-edit" value="tue|22:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r043-edit">22:00</label>

                                            <input type="checkbox" name="rGroup" id="r044-edit" value="tue|22:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r044-edit">22:30</label>

                                            <input type="checkbox" name="rGroup" id="r045-edit" value="tue|23:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r045">23:00</label>

                                            <input type="checkbox" name="rGroup" id="r046-edit" value="tue|23:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r046-edit">23:30</label>

                                            <input type="checkbox" name="rGroup" id="r047-edit" value="tue|24:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r047-edit">24:00</label>

                                            <input type="checkbox" name="rGroup" id="r048-edit" value="tue|24:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r048-edit">24:30</label>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade " id="wed-edit">
                                        <div class="hours mt-2 invisible-checkboxes">
                                            <input type="checkbox" name="rGroup" id="r001-edit" value="wed|01:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r001-edit">01:00</label>

                                            <input type="checkbox" name="rGroup" id="r002-edit" value="wed|01:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r002-edit">01:30</label>

                                            <input type="checkbox" name="rGroup" id="r003-edit" value="web|02:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r003-edit">02:00</label>

                                            <input type="checkbox" name="rGroup" id="r004-edit" value="wed|02:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r004-edit">02:30</label>

                                            <input type="checkbox" name="rGroup" id="r005-edit" value="wed|03:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r005-edit">03:00</label>

                                            <input type="checkbox" name="rGroup" id="r006-edit" value="wed|03:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r006-edit">03:30</label>

                                            <input type="checkbox" name="rGroup" id="r007-edit" value="wed|04:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r007-edit">04:00</label>

                                            <input type="checkbox" name="rGroup" id="r008-edit" value="wed|04:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r008-edit">04:30</label>

                                            <input type="checkbox" name="rGroup" id="r009-edit" value="wed|05:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r009-edit">05:00</label>

                                            <input type="checkbox" name="rGroup" id="r0010-edit" value="wed|05:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0010-edit">05:30</label>

                                            <input type="checkbox" name="rGroup" id="r0011-edit" value="wed|06:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0011-edit">06:00</label>

                                            <input type="checkbox" name="rGroup" id="r0012-edit" value="wed|06:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0012-edit">06:30</label>

                                            <input type="checkbox" name="rGroup" id="r0013-edit" value="wed|07:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0013-edit">07:00</label>

                                            <input type="checkbox" name="rGroup" id="r0014-edit" value="wed|07:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0014-edit">07:30</label>

                                            <input type="checkbox" name="rGroup" id="r0015-edit" value="wed|08:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0015-edit">08:00</label>

                                            <input type="checkbox" name="rGroup" id="r0016-edit" value="wed|08:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0016-edit">08:30</label>

                                            <input type="checkbox" name="rGroup" id="r0017-edit" value="wed|09:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0017-edit">09:00</label>

                                            <input type="checkbox" name="rGroup" id="r0018-edit" value="wed|09:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0018-edit">09:30</label>

                                            <input type="checkbox" name="rGroup" id="r0019-edit" value="wed|10:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0019-edit">10:00</label>

                                            <input type="checkbox" name="rGroup" id="r0020-edit" value="wed|10:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0020-edit">10:30</label>

                                            <input type="checkbox" name="rGroup" id="r0021-edit" value="wed|11:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0021-edit">11:00</label>

                                            <input type="checkbox" name="rGroup" id="r0022-edit" value="wed|11:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0022-edit">11:30</label>

                                            <input type="checkbox" name="rGroup" id="r0023-edit" value="wed|12:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0023-edit">12:00</label>

                                            <input type="checkbox" name="rGroup" id="r0024-edit" value="wed|12:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0024-edit">12:30</label>

                                            <input type="checkbox" name="rGroup" id="r0025-edit" value="wed|13:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0025-edit">13:00</label>

                                            <input type="checkbox" name="rGroup" id="r0026-edit" value="wed|13:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0026-edit">13:30</label>

                                            <input type="checkbox" name="rGroup" id="r0027-edit" value="wed|14:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0027-edit">14:00</label>

                                            <input type="checkbox" name="rGroup" id="r0028-edit" value="wed|14:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0028-edit">14:30</label>

                                            <input type="checkbox" name="rGroup" id="r0029-edit" value="wed|15:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0029-edit">15:00</label>

                                            <input type="checkbox" name="rGroup" id="r0030-edit" value="wed|15:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0030-edit">15:30</label>

                                            <input type="checkbox" name="rGroup" id="r0031-edit" value="wed|16:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0031-edit">16:00</label>

                                            <input type="checkbox" name="rGroup" id="r0032-edit" value="wed|16:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0032-edit">16:30</label>

                                            <input type="checkbox" name="rGroup" id="r0033-edit" value="wed|17:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0033-edit">17:00</label>

                                            <input type="checkbox" name="rGroup" id="r0034-edit" value="wed|17:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0034-edit">17:30</label>

                                            <input type="checkbox" name="rGroup" id="r0035-edit" value="wed|18:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0035-edit">18:00</label>

                                            <input type="checkbox" name="rGroup" id="r0036-edit" value="wed|18:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0036-edit">18:30</label>

                                            <input type="checkbox" name="rGroup" id="r0037-edit" value="wed|19:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0037-edit">19:00</label>

                                            <input type="checkbox" name="rGroup" id="r0038-edit" value="wed|19:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0038-edit">19:30</label>

                                            <input type="checkbox" name="rGroup" id="r0039-edit" value="wed|20:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0039-edit">20:00</label>

                                            <input type="checkbox" name="rGroup" id="r0040-edit" value="wed|20:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0040-edit">20:30</label>

                                            <input type="checkbox" name="rGroup" id="r0041-edit" value="wed|21:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0041-edit">21:00</label>

                                            <input type="checkbox" name="rGroup" id="r0042-edit" value="wed|21:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0042-edit">21:30</label>

                                            <input type="checkbox" name="rGroup" id="r0043-edit" value="wed|22:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0043-edit">22:00</label>

                                            <input type="checkbox" name="rGroup" id="r0044-edit" value="wed|22:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0044-edit">22:30</label>

                                            <input type="checkbox" name="rGroup" id="r0045-edit" value="wed|23:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0045-edit">23:00</label>

                                            <input type="checkbox" name="rGroup" id="r0046-edit" value="wed|23:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0046-edit">23:30</label>

                                            <input type="checkbox" name="rGroup" id="r0047-edit" value="wed|24:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0047-edit">24:00</label>

                                            <input type="checkbox" name="rGroup" id="r0048-edit" value="wed|24:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r0048-edit">24:30</label>
                                        </div>

                                    </div>
                                    <div class="tab-pane fade" id="thu-edit">
                                        <div class="hours mt-2 invisible-checkboxes">
                                            <input type="checkbox" name="rGroup" id="r_1-edit" value="thu|01:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_1-edit">01:00</label>

                                            <input type="checkbox" name="rGroup" id="r_2-edit" value="thu|01:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_2-edit">01:30</label>

                                            <input type="checkbox" name="rGroup" id="r_3-edit" value="thu|02:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_3-edit">02:00</label>

                                            <input type="checkbox" name="rGroup" id="r_4-edit" value="thu|02:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_4-edit">02:30</label>

                                            <input type="checkbox" name="rGroup" id="r_5-edit" value="thu|03:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_5-edit">03:00</label>

                                            <input type="checkbox" name="rGroup" id="r_6-edit" value="thu|03:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_6-edit">03:30</label>

                                            <input type="checkbox" name="rGroup" id="r_7-edit" value="thu|04:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_7-edit">04:00</label>

                                            <input type="checkbox" name="rGroup" id="r_8-edit" value="thu|04:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_8-edit">04:30</label>

                                            <input type="checkbox" name="rGroup" id="r_9-edit" value="thu|05:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_9-edit">05:00</label>

                                            <input type="checkbox" name="rGroup" id="r_10-edit" value="thu|05:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_10-edit">05:30</label>

                                            <input type="checkbox" name="rGroup" id="r_11-edit" value="thu|06:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_11-edit">06:00</label>

                                            <input type="checkbox" name="rGroup" id="r_12-edit" value="thu|06:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_12-edit">06:30</label>

                                            <input type="checkbox" name="rGroup" id="r_13-edit" value="thu|07:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_13-edit">07:00</label>

                                            <input type="checkbox" name="rGroup" id="r_14-edit" value="thu|07:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_14-edit">07:30</label>

                                            <input type="checkbox" name="rGroup" id="r_15-edit" value="thu|08:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_15-edit">08:00</label>

                                            <input type="checkbox" name="rGroup" id="r_16-edit" value="thu|08:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_16-edit">08:30</label>

                                            <input type="checkbox" name="rGroup" id="r_17-edit" value="thu|09:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_17-edit">09:00</label>

                                            <input type="checkbox" name="rGroup" id="r_18-edit" value="thu|09:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_18-edit">09:30</label>

                                            <input type="checkbox" name="rGroup" id="r_19-edit" value="thu|10:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_19-edit">10:00</label>

                                            <input type="checkbox" name="rGroup" id="r_20-edit" value="thu|10:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_20-edit">10:30</label>

                                            <input type="checkbox" name="rGroup" id="r_21-edit" value="thu|11:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_21-edit">11:00</label>

                                            <input type="checkbox" name="rGroup" id="r_22-edit" value="thu|11:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_22-edit">11:30</label>

                                            <input type="checkbox" name="rGroup" id="r_23-edit" value="thu|12:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_23-edit">12:00</label>

                                            <input type="checkbox" name="rGroup" id="r_24-edit" value="thu|12:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_24-edit">12:30</label>

                                            <input type="checkbox" name="rGroup" id="r_25-edit" value="thu|13:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_25-edit">13:00</label>

                                            <input type="checkbox" name="rGroup" id="r_26-edit" value="thu|13:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_26-edit">13:30</label>

                                            <input type="checkbox" name="rGroup" id="r_27-edit" value="thu|14:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_27-edit">14:00</label>

                                            <input type="checkbox" name="rGroup" id="r_28-edit" value="thu|14:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_28-edit">14:30</label>

                                            <input type="checkbox" name="rGroup" id="r_29-edit" value="thu|15:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_29-edit">15:00</label>

                                            <input type="checkbox" name="rGroup" id="r_30-edit" value="thu|15:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_30-edit">15:30</label>

                                            <input type="checkbox" name="rGroup" id="r_31-edit" value="thu|16:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_31-edit">16:00</label>

                                            <input type="checkbox" name="rGroup" id="r_32-edit" value="thu|16:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_32-edit">16:30</label>

                                            <input type="checkbox" name="rGroup" id="r_33-edit" value="thu|17:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_33-edit">17:00</label>

                                            <input type="checkbox" name="rGroup" id="r_34-edit" value="thu|17:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_34-edit">17:30</label>

                                            <input type="checkbox" name="rGroup" id="r_35-edit" value="thu|18:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_35-edit">18:00</label>

                                            <input type="checkbox" name="rGroup" id="r_36-edit" value="thu|18:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_36-edit">18:30</label>

                                            <input type="checkbox" name="rGroup" id="r_37-edit" value="thu|19:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_37-edit">19:00</label>

                                            <input type="checkbox" name="rGroup" id="r_38-edit" value="thu|19:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_38-edit">19:30</label>

                                            <input type="checkbox" name="rGroup" id="r_39-edit" value="thu|20:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_39-edit">20:00</label>

                                            <input type="checkbox" name="rGroup" id="r_40-edit" value="thu|20:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_40-edit">20:30</label>

                                            <input type="checkbox" name="rGroup" id="r_41-edit" value="thu|21:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_41-edit">21:00</label>

                                            <input type="checkbox" name="rGroup" id="r_42-edit" value="thu|21:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_42-edit">21:30</label>

                                            <input type="checkbox" name="rGroup" id="r_43-edit" value="thu|22:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_43-edit">22:00</label>

                                            <input type="checkbox" name="rGroup" id="r_44-edit" value="thu|22:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_44-edit">22:30</label>

                                            <input type="checkbox" name="rGroup" id="r_45-edit" value="thu|23:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_45-edit">23:00</label>

                                            <input type="checkbox" name="rGroup" id="r_46-edit" value="thu|23:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_46-edit">23:30</label>

                                            <input type="checkbox" name="rGroup" id="r_47-edit" value="thu|24:00:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_47-edit">24:00</label>

                                            <input type="checkbox" name="rGroup" id="r_48-edit" value="thu|24:30:00" v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r_48-edit">24:30</label>

                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="fri-edit">
                                        <div class="hours mt-2 invisible-checkboxes">
                                            <input type="checkbox" name="rGroup" id="r-1-edit" value="fri|01:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-1-edit">01:00</label>

                                            <input type="checkbox" name="rGroup" id="r-2-edit" value="fri|01:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-2-edit">01:30</label>

                                            <input type="checkbox" name="rGroup" id="r-3-edit" value="fri|02:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-3-edit">02:00</label>

                                            <input type="checkbox" name="rGroup" id="r-4-edit" value="fri|02:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-4-edit">02:30</label>

                                            <input type="checkbox" name="rGroup" id="r-5-edit" value="fri|03:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-5-edit">03:00</label>

                                            <input type="checkbox" name="rGroup" id="r-6-edit" value="fri|03:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-6-edit">03:30</label>

                                            <input type="checkbox" name="rGroup" id="r-7-edit" value="fri|04:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-7-edit">04:00</label>

                                            <input type="checkbox" name="rGroup" id="r-8-edit" value="fri|04:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-8-edit">04:30</label>

                                            <input type="checkbox" name="rGroup" id="r-9-edit" value="fri|05:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-9-edit">05:00</label>

                                            <input type="checkbox" name="rGroup" id="r-10-edit" value="fri|05:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-10-edit">05:30</label>

                                            <input type="checkbox" name="rGroup" id="r-11-edit" value="fri|06:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-11-edit">06:00</label>

                                            <input type="checkbox" name="rGroup" id="r-12-edit" value="fri|06:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-12-edit">06:30</label>

                                            <input type="checkbox" name="rGroup" id="r-13-edit" value="fri|07:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-13-edit">07:00</label>

                                            <input type="checkbox" name="rGroup" id="r-14-edit" value="fri|07:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-14-edit">07:30</label>

                                            <input type="checkbox" name="rGroup" id="r-15-edit" value="fri|08:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-15-edit">08:00</label>

                                            <input type="checkbox" name="rGroup" id="r-16-edit" value="fri|08:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-16-edit">08:30</label>

                                            <input type="checkbox" name="rGroup" id="r-17-edit" value="fri|09:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-17-edit">09:00</label>

                                            <input type="checkbox" name="rGroup" id="r-18-edit" value="fri|09:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-18-edit">09:30</label>

                                            <input type="checkbox" name="rGroup" id="r-19-edit" value="fri|10:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-19-edit">10:00</label>

                                            <input type="checkbox" name="rGroup" id="r-20-edit" value="fri|10:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-20-edit">10:30</label>

                                            <input type="checkbox" name="rGroup" id="r-21-edit" value="fri|11:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-21-edit">11:00</label>

                                            <input type="checkbox" name="rGroup" id="r-22-edit" value="fri|11:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-22-edit">11:30</label>

                                            <input type="checkbox" name="rGroup" id="r-23-edit" value="fri|12:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-23-edit">12:00</label>

                                            <input type="checkbox" name="rGroup" id="r-24-edit" value="fri|12:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-24-edit">12:30</label>

                                            <input type="checkbox" name="rGroup" id="r-25-edit" value="fri|13:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-25-edit">13:00</label>

                                            <input type="checkbox" name="rGroup" id="r-26-edit" value="fri|13:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-26-edit">13:30</label>

                                            <input type="checkbox" name="rGroup" id="r-27-edit" value="fri|14:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-27-edit">14:00</label>

                                            <input type="checkbox" name="rGroup" id="r-28-edit" value="fri|14:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-28-edit">14:30</label>

                                            <input type="checkbox" name="rGroup" id="r-29-edit" value="fri|15:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-29-edit">15:00</label>

                                            <input type="checkbox" name="rGroup" id="r-30-edit" value="fri|15:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-30-edit">15:30</label>

                                            <input type="checkbox" name="rGroup" id="r-31-edit" value="fri|16:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-31-edit">16:00</label>

                                            <input type="checkbox" name="rGroup" id="r-32-edit" value="fri|16:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-32-edit">16:30</label>

                                            <input type="checkbox" name="rGroup" id="r-33-edit" value="fri|17:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-33-edit">17:00</label>

                                            <input type="checkbox" name="rGroup" id="r-34-edit" value="fri|17:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-34-edit">17:30</label>

                                            <input type="checkbox" name="rGroup" id="r-35-edit" value="fri|18:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-35-edit">18:00</label>

                                            <input type="checkbox" name="rGroup" id="r-36-edit" value="fri|18:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-36-edit">18:30</label>

                                            <input type="checkbox" name="rGroup" id="r-37-edit" value="fri|19:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-37-edit">19:00</label>

                                            <input type="checkbox" name="rGroup" id="r-38-edit" value="fri|19:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-38-edit">19:30</label>

                                            <input type="checkbox" name="rGroup" id="r-39-edit" value="fri|20:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-39-edit">20:00</label>

                                            <input type="checkbox" name="rGroup" id="r-40-edit" value="fri|20:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-40-edit">20:30</label>

                                            <input type="checkbox" name="rGroup" id="r-41-edit" value="fri|21:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-41-edit">21:00</label>

                                            <input type="checkbox" name="rGroup" id="r-42-edit" value="fri|21:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-42-edit">21:30</label>

                                            <input type="checkbox" name="rGroup" id="r-43-edit" value="fri|22:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-43-edit">22:00</label>

                                            <input type="checkbox" name="rGroup" id="r-44-edit" value="fri|22:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-44-edit">22:30</label>

                                            <input type="checkbox" name="rGroup" id="r-45-edit" value="fri|23:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-45-edit">23:00</label>

                                            <input type="checkbox" name="rGroup" id="r-46-edit" value="fri|23:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-46-edit">23:30</label>

                                            <input type="checkbox" name="rGroup" id="r-47-edit" value="fri|24:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-47-edit">24:00</label>

                                            <input type="checkbox" name="rGroup" id="r-48-edit" value="fri|24:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r-48-edit">24:30</label>

                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="sat-edit">
                                        <div class="hours mt-2 invisible-checkboxes">
                                            <input type="checkbox" name="rGroup" id="r1a-edit" value="sat|01:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r1a-edit">01:00</label>

                                            <input type="checkbox" name="rGroup" id="r2a-edit" value="sat|01:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r2a-edit">01:30</label>

                                            <input type="checkbox" name="rGroup" id="r3a-edit" value="sat|02:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r3a-edit">02:00</label>

                                            <input type="checkbox" name="rGroup" id="r4a-edit" value="sat|02:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r4a-edit">02:30</label>

                                            <input type="checkbox" name="rGroup" id="r5a-edit" value="sat|03:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r5a-edit">03:00</label>

                                            <input type="checkbox" name="rGroup" id="r6a-edit" value="sat|03:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r6a-edit">03:30</label>

                                            <input type="checkbox" name="rGroup" id="r7a-edit" value="sat|04:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r7a-edit">04:00</label>

                                            <input type="checkbox" name="rGroup" id="r8a-edit" value="sat|04:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r8a-edit">04:30</label>

                                            <input type="checkbox" name="rGroup" id="r9a-edit" value="sat|05:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r9a-edit">05:00</label>

                                            <input type="checkbox" name="rGroup" id="r10a-edit" value="sat|05:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r10a-edit">05:30</label>

                                            <input type="checkbox" name="rGroup" id="r11a-edit" value="sat|06:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r11a-edit">06:00</label>

                                            <input type="checkbox" name="rGroup" id="r12a-edit" value="sat|06:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r12a-edit">06:30</label>

                                            <input type="checkbox" name="rGroup" id="r13a-edit" value="sat|07:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r13a-edit">07:00</label>

                                            <input type="checkbox" name="rGroup" id="r14a-edit" value="sat|07:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r14a-edit">07:30</label>

                                            <input type="checkbox" name="rGroup" id="r15a-edit" value="sat|08:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r15a-edit">08:00</label>

                                            <input type="checkbox" name="rGroup" id="r16a-edit" value="sat|08:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r16a-edit">08:30</label>

                                            <input type="checkbox" name="rGroup" id="r17a-edit" value="sat|09:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r17a-edit">09:00</label>

                                            <input type="checkbox" name="rGroup" id="r18a-edit" value="sat|09:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r18a-edit">09:30</label>

                                            <input type="checkbox" name="rGroup" id="r19a-edit" value="sat|10:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r19a-edit">10:00</label>

                                            <input type="checkbox" name="rGroup" id="r20a-edit" value="sat|10:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r20a-edit">10:30</label>

                                            <input type="checkbox" name="rGroup" id="r21a-edit" value="sat|11:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r21a-edit">11:00</label>

                                            <input type="checkbox" name="rGroup" id="r22a-edit" value="sat|11:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r22a-edit">11:30</label>

                                            <input type="checkbox" name="rGroup" id="r23a-edit" value="sat|12:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r23a-edit">12:00</label>

                                            <input type="checkbox" name="rGroup" id="r24a-edit" value="sat|12:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r24a-edit">12:30</label>

                                            <input type="checkbox" name="rGroup" id="r25a-edit" value="sat|13:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r25a-edit">13:00</label>

                                            <input type="checkbox" name="rGroup" id="r26a-edit" value="sat|13:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r26a-edit">13:30</label>

                                            <input type="checkbox" name="rGroup" id="r27a-edit" value="sat|14:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r27a-edit">14:00</label>

                                            <input type="checkbox" name="rGroup" id="r28a-edit" value="sat|14:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r28a-edit">14:30</label>

                                            <input type="checkbox" name="rGroup" id="r29a-edit" value="sat|15:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r29a-edit">15:00</label>

                                            <input type="checkbox" name="rGroup" id="r30a-edit" value="sat|15:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r30a-edit">15:30</label>

                                            <input type="checkbox" name="rGroup" id="r31a-edit" value="sat|16:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r31a-edit">16:00</label>

                                            <input type="checkbox" name="rGroup" id="r32a-edit" value="sat|16:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r32a-edit">16:30</label>

                                            <input type="checkbox" name="rGroup" id="r33a-edit" value="sat|17:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r33a-edit">17:00</label>

                                            <input type="checkbox" name="rGroup" id="r34a-edit" value="sat|17:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r34a-edit">17:30</label>

                                            <input type="checkbox" name="rGroup" id="r35a-edit" value="sat|18:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r35a-edit">18:00</label>

                                            <input type="checkbox" name="rGroup" id="r36a-edit" value="sat|18:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r36a-edit">18:30</label>

                                            <input type="checkbox" name="rGroup" id="r37a-edit" value="sat|19:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r37a-edit">19:00</label>

                                            <input type="checkbox" name="rGroup" id="r38a-edit" value="sat|19:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r38a-edit">19:30</label>

                                            <input type="checkbox" name="rGroup" id="r39a-edit" value="sat|20:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r39a-edit">20:00</label>

                                            <input type="checkbox" name="rGroup" id="r40a-edit" value="sat|20:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r40a-edit">20:30</label>

                                            <input type="checkbox" name="rGroup" id="r41a-edit" value="sat|21:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r41a-edit">21:00</label>

                                            <input type="checkbox" name="rGroup" id="r42a-edit" value="sat|21:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r42a-edit">21:30</label>

                                            <input type="checkbox" name="rGroup" id="r43a-edit" value="sat|22:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r43a-edit">22:00</label>

                                            <input type="checkbox" name="rGroup" id="r44a-edit" value="sat|22:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r44a-edit">22:30</label>

                                            <input type="checkbox" name="rGroup" id="r45a-edit" value="sat|23:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r45a-edit">23:00</label>

                                            <input type="checkbox" name="rGroup" id="r46a-edit" value="sat|23:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r46a-edit">23:30</label>

                                            <input type="checkbox" name="rGroup" id="r47a-edit" value="sat|24:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r47a-edit">24:00</label>

                                            <input type="checkbox" name="rGroup" id="r48a-edit" value="sat|24:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r48a-edit">24:30</label>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="sun-edit">
                                        <div class="hours mt-2 invisible-checkboxes">
                                            <input type="checkbox" name="rGroup" id="r1b-edit" value="sun|01:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r1b-edit">01:00</label>

                                            <input type="checkbox" name="rGroup" id="r2b-edit" value="sun|01:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r2b-edit">01:30</label>

                                            <input type="checkbox" name="rGroup" id="r3b-edit" value="sun|02:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r3b-edit">02:00</label>

                                            <input type="checkbox" name="rGroup" id="r4b-edit" value="sun|02:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r4b-edit">02:30</label>

                                            <input type="checkbox" name="rGroup" id="r5b-edit" value="sun|03:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r5b-edit">03:00</label>

                                            <input type="checkbox" name="rGroup" id="r6b-edit" value="sun|03:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r6b-edit">03:30</label>

                                            <input type="checkbox" name="rGroup" id="r7b-edit" value="sun|04:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r7b-edit">04:00</label>

                                            <input type="checkbox" name="rGroup" id="r8b-edit" value="sun|04:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r8b-edit">04:30</label>

                                            <input type="checkbox" name="rGroup" id="r9b-edit" value="sun|05:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r9b-edit">05:00</label>

                                            <input type="checkbox" name="rGroup" id="r10b-edit" value="sun|05:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r10b-edit">05:30</label>

                                            <input type="checkbox" name="rGroup" id="r11b-edit" value="sun|06:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r11b-edit">06:00</label>

                                            <input type="checkbox" name="rGroup" id="r12b-edit" value="sun|06:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r12b-edit">06:30</label>

                                            <input type="checkbox" name="rGroup" id="r13b-edit" value="sun|07:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r13b-edit">07:00</label>

                                            <input type="checkbox" name="rGroup" id="r14b-edit" value="sun|07:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r14b-edit">07:30</label>

                                            <input type="checkbox" name="rGroup" id="r15b-edit" value="sun|08:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r15b-edit">08:00</label>

                                            <input type="checkbox" name="rGroup" id="r16b-edit" value="sun|08:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r16b-edit">08:30</label>

                                            <input type="checkbox" name="rGroup" id="r17b-edit" value="sun|09:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r17b-edit">09:00</label>

                                            <input type="checkbox" name="rGroup" id="r18b-edit" value="sun|09:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r18b-edit">09:30</label>

                                            <input type="checkbox" name="rGroup" id="r19b-edit" value="sun|10:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r19b-edit">10:00</label>

                                            <input type="checkbox" name="rGroup" id="r20b-edit" value="sun|10:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r20b-edit">10:30</label>

                                            <input type="checkbox" name="rGroup" id="r21b-edit" value="sun|11:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r21b-edit">11:00</label>

                                            <input type="checkbox" name="rGroup" id="r22b-edit" value="sun|11:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r22b-edit">11:30</label>

                                            <input type="checkbox" name="rGroup" id="r23b-edit" value="sun|12:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r23b-edit">12:00</label>

                                            <input type="checkbox" name="rGroup" id="r24b-edit" value="sun|12:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r24b-edit">12:30</label>

                                            <input type="checkbox" name="rGroup" id="r25b-edit" value="sun|13:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r25b-edit">13:00</label>

                                            <input type="checkbox" name="rGroup" id="r26b-edit" value="sun|13:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r26b-edit">13:30</label>

                                            <input type="checkbox" name="rGroup" id="r27b-edit" value="sun|14:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r27b-edit">14:00</label>

                                            <input type="checkbox" name="rGroup" id="r28b-edit" value="sun|14:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r28b-edit">14:30</label>

                                            <input type="checkbox" name="rGroup" id="r29b-edit" value="sun|15:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r29b-edit">15:00</label>

                                            <input type="checkbox" name="rGroup" id="r30b-edit" value="sun|15:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r30b-edit">15:30</label>

                                            <input type="checkbox" name="rGroup" id="r31b-edit" value="sun|16:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r31b-edit">16:00</label>

                                            <input type="checkbox" name="rGroup" id="r32b-edit" value="sun|16:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r32b-edit">16:30</label>

                                            <input type="checkbox" name="rGroup" id="r33b-edit" value="sun|17:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r33b-edit">17:00</label>

                                            <input type="checkbox" name="rGroup" id="r34b-edit" value="sun|17:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r34b-edit">17:30</label>

                                            <input type="checkbox" name="rGroup" id="r35b-edit" value="sun|18:00:00"  v-model="form.workingTimeEdit" 
                                                >
                                            <label class="checkbox-alias" for="r35b-edit">18:00</label>

                                            <input type="checkbox" name="rGroup" id="r36b-edit" value="sun|18:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r36b-edit">18:30</label>

                                            <input type="checkbox" name="rGroup" id="r37b-edit" value="sun|19:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r37b-edit">19:00</label>

                                            <input type="checkbox" name="rGroup" id="r38b-edit" value="sun|19:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r38b-edit">19:30</label>

                                            <input type="checkbox" name="rGroup" id="r39b-edit" value="sun|20:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r39b-edit">20:00</label>

                                            <input type="checkbox" name="rGroup" id="r40b-edit" value="sun|20:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r40b-edit">20:30</label>

                                            <input type="checkbox" name="rGroup" id="r41b-edit" value="sun|21:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r41b-edit">21:00</label>

                                            <input type="checkbox" name="rGroup" id="r42b-edit" value="sun|21:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r42b-edit">21:30</label>

                                            <input type="checkbox" name="rGroup" id="r43b-edit" value="sun|22:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r43b-edit">22:00</label>

                                            <input type="checkbox" name="rGroup" id="r44b-edit" value="sun|22:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r44b-edit">22:30</label>

                                            <input type="checkbox" name="rGroup" id="r45b-edit" value="sun|23:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r45b-edit">23:00</label>

                                            <input type="checkbox" name="rGroup" id="r46b-edit" value="sun|23:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r46b-edit">23:30</label>

                                            <input type="checkbox" name="rGroup" id="r47b-edit" value="sun|24:00:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r47b-edit">24:00</label>

                                            <input type="checkbox" name="rGroup" id="r48b-edit" value="sun|24:30:00"  v-model="form.workingTimeEdit"
                                                >
                                            <label class="checkbox-alias" for="r48b-edit">24:30</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
</template>
<script>
export default {
    props: ["techWorkingSlot"],
    data(){
    return {
        form :{
            workingTimeEdit: [],
        }
    }
  },
  created() {

this.form.workingTimeEdit = this.techWorkingSlot;

 },
 methods:{
    gettingDataAction(){
        this.form.workingTimeEdit = this.techWorkingSlot;
       
    },
    emitDataAction(){
        this.$emit('workingTimeSlot',  this.form.workingTimeEdit );
    }
}
}

</script>
