<template>
  <div class="d-flex flex-column flex-root">
    <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">
      <div class="login-aside d-flex flex-column flex-row-auto bg-login-aside">
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15 margintop">
          <!--begin::Aside header-->
          <a href="#" class="text-center mb-10">
            <h3 class="text-center mt-2 mb-5">
              <img :src="logoImage" alt="Logo" />
            </h3>
          </a>
          <!--end::Aside header-->
          <!--begin::Aside title-->
          <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg orange-color-api">
            Discover Amazing Tool <br />
          </h3>
          <div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"></div>
          <!--end::Aside title-->
        </div>
      </div>
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div class="d-flex flex-column-fluid flex-center">
          <div class="p-5">
            <!-- <h3 class="text-center mt-2 mb-5">
              <img src="images/logo.svg" alt="Logo" />
            </h3> -->
            <div class="col-md-12">
              <form v-on:submit.prevent="login_user" id="login-form">
                <div class="pb-13 pt-lg-0 pt-5">
                  <h3 class="font-weight-bolder font-size-h4 font-size-h1-lg orange-color-api">
                    Welcome to {{ businessName }}
                  </h3>
                </div>
                <div class="mb-3 mt-5">
                  <label for="exampleFormControlInput2"
                    class="font-size-h6 font-weight-bolder text-dark-api">Email</label>
                  <input type="text" name="email" class="form-control form-control-solid h-auto rounded-lg" id="email"
                    aria-describedby="emailHelp" placeholder="Enter Email" v-model="form.email" required/>
                </div>
                <div class="mb-3">
                  <label for="exampleFormControlInput3"
                    class="font-size-h6 font-weight-bolder text-dark-api">Password</label>
                  <input type="password" name="password" class="form-control form-control-solid h-auto rounded-lg"
                    id="password" aria-describedby="passHelp" placeholder="Enter Password" v-model="form.password" />
                </div>

                <ul class="text-danger" v-for="(errorArray, idx) in notifmsg" :key="idx">
                  {{ errorArray }}
                </ul>

                <button type="submit" class="button-sized btn btn-primary mt-5">
                  Login
                </button>
              </form>
              <div class="mt-4">
                <!-- <span class="text-dark-api font-weight-bold font-size-h4">
                  Don't have an account?
                  <a href="/register" class="text-primary-green font-weight-bolder green-color-api">Create an Account
                  </a></span> -->
                <!-- <div class="d-flex justify-content-center links">
                 
                  <a href="/register" class="ml-2">Sign Up</a>
                </div> -->
                <div class="d-flex justify-content-center links">
                  <!-- <a href="#">Forgot your password?</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import router from '../router';
export default {
  data() {
    return {
      notifmsg: '',
      form: {
        email: '',
        password: '',
      },
      notifmsg: [],
      logoImage: '',
      businessName: "",
    };
  },
  created() {
    axios
      .get('/api/business-setting')
      .then((resp) => {
        if (resp.data) {
          this.logoImage = resp.data.businessetting[0].headerLogoUrl;
          let setting = resp.data.businessetting[0];
          let settingTitle = resp.data.businessdata[0];
          console.log("first color", settingTitle);
          const root = document.querySelector(':root');
          if (setting.colorA)
            root.style.setProperty('--colorA', setting.colorA);
           localStorage.setItem('colorA', setting.colorA);
          if (setting.colorB)
            root.style.setProperty('--colorB', setting.colorB);
           localStorage.setItem('colorB', setting.colorB);
          if (setting.colorC)
            root.style.setProperty('--colorC', setting.colorC);
           localStorage.setItem('colorC', setting.colorC);
          if (setting.colorD)
            root.style.setProperty('--colorD', setting.colorD);
           localStorage.setItem('colorD', setting.colorD);
          if (setting.headingFont)
            root.style.setProperty('--font-heading', setting.headingFont);
          if (setting.bodyFont)
            root.style.setProperty('--font-body', setting.bodyFont);
          if (settingTitle.business_name)
           this.businessName = settingTitle.business_name;
            root.style.setProperty('--bussiness-name', settingTitle.business_name);
           localStorage.setItem('business_name', settingTitle.business_name);
          if (setting.headerLogoUrl)
            root.style.setProperty('--headerLogoUrl', setting.headerLogoUrl);
           localStorage.setItem('headerLogoUrl', setting.headerLogoUrl);
        } else {
          
          Swal.fire({
            title: 'Oops',
            text: 'No Website found',
            icon: 'warning',
          });
        }
      })
      .catch((e) => {
        this.notifmsg = e.response.data;
      });

  },
  methods: {
    login_user() {
      const emailPattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!emailPattern.test(this.form.email)) {
        Swal.fire({
          title: 'Invalid Email',
          text: 'Please enter a valid email address.',
          icon: 'error',
        });
        return;
      }

      if (this.form.password.length < 7) {
        Swal.fire({
          title: 'Invalid Password',
          text: 'Password must be 8 characters.',
          icon: 'error',
        });
        return;
      }

      axios
        .post('/api/login', this.form)
        .then((resp) => {
          this.form.email = '';
          this.form.password = '';
          if (resp['data']['user']) {
            localStorage.setItem('usertoken', resp['data']['user']);
            this.email = '';
            this.password = '';
            window.location.href = "/dashboard";
            //router.push({ name: 'dashboard' }).reload();
          } else {
            Swal.fire({
              title: 'Oops',
              text: 'Please Enter Valid Email Address and Password!',
              icon: 'warning',
            });
          }
        })
        .catch((e) => {
          this.notifmsg = e.response.data;
        });
    },
  },


};

</script>
<style scoped>
@media (min-width: 992px) and (max-width: 1399.98px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 450px;
  }

  .pt-lg-40,
  .py-lg-40 {
    padding-top: 10rem !important;
  }
}

.form-control.form-control-solid {
  background-color: #ebedf3;
  border-color: #ebedf3;

  padding: 15px 0px 15px 10px;
  color: #3f4254;
  /* -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease; */
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}

input#email1 {
  width: 370px;
}

.rounded-lg {
  border-radius: 0.85rem !important;
}

.button-sized {
  padding: 15px 25px 15px 25px;
  border-radius: 8px !important;
  font-weight: 700;
  background-color: var(--colorA) !important;
  border: 1px solid var(--colorA) !important;
  color: var(--colorD);

}

.font-size-h6 {
  font-size: 1.175rem !important;
}

.login.login-1.login-signin-on.d-flex.flex-column.flex-lg-row.flex-column-fluid.bg-white {
  height: 100vh !important;
}
</style>