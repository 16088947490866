<template>
    <section class="pos-rel-index">
        <div class="calender-listing ">
            <ul class="owl-carousel calender-carousel-horizontal dragscroll">
                <li class="item active" v-on:click="pickedDate(todayDateClicked)">
                    <button class="calendar-btn" :style="buttonStyle">
                        <h5>Today, {{ today_date }}</h5>
                        <p>{{ totalTodayAppoitment }} Appoinment</p>
                    </button>
                </li>
                <li v-for="(data, index) in otherAppointmentData" :key="index" v-on:click="pickedDate(data.dateRecord)"
                    :class="activeMonthClass[index]">
                    <button class="calendar-btn" v-on:click="setActiveDay(index)" :style="buttonStyle">
                        <h5>{{ convertingDate(data.dateRecord) }}</h5>
                        <p>{{ data.appointmentCount }} </p>
                    </button>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import moment from "moment";
import { eventBus } from '../event-bus';
export default {
    props: ['parentMessage'],
    data() {
        return {
            calendarData: [

                { date: 'SUN 23', appointments: 13 },
                { date: 'MON 24', appointments: 4 },
                { date: 'TUE 25', appointments: 8 },
                { date: 'WED 26', appointments: 10 },
                { date: 'THU 27', appointments: 0 },
                { date: 'FRI 28', appointments: 0 },
                { date: 'SAT 29', appointments: 0 },
                { date: 'SUN 30', appointments: 13 },
                { date: 'MON 31', appointments: 13 },
                { date: 'NOV 1', appointments: 0 },
            ],
            activeDayIndex: 0,
            today_date: '',
            totalTodayAppoitment: '',
            otherAppointmentData: [],
            todayDateClicked: '',
            activeMonthClass: [],
            colorA: localStorage.getItem('colorA') || '#000000',
            colorB: localStorage.getItem('colorB') || '#000000',
            desiredOpacity: 0.3,
        };
    },
    mounted() {
        this.$nextTick(() => {
            const $carousel = $(this.$el).find('.owl-carousel');
        });
        // eventBus.$on('updateSettings', (newSettings) => {
        //     this.settings = newSettings;
        // });
    },

    beforeCreate() {
        axios.get('/api/appointment?user_id=' + localStorage.getItem('usertoken'), {
            headers: {
                "Accept": "application/json"
            },
        }).then((resp) => {
            this.todayDateClicked = resp.data['todayData'][0].dateRecord;
            this.today_date = moment(resp.data['todayData'][0].dateRecord).format("dddd MMM DD");
            this.totalTodayAppoitment = resp.data['todayData'][0].appointmentCount;
            setTimeout(() => {
                this.otherAppointmentData = resp.data['otherDayData'];
            }, 1000);

        });

    },
    computed: {
        buttonStyle() {
            return {
                '--button-background': this.convertColorToRGBA(this.colorA, 0.5),
            };
        },

    },
    methods: {
        convertColorToRGBA(color, opacity) {
            const hexToRgb = (hex) =>
                hex
                    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
                    .substring(1)
                    .match(/.{2}/g)
                    .map((x) => parseInt(x, 16));

            const [r, g, b] = hexToRgb(color);
            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
        },
        hexToRGBA(hex, opacity) {
            // return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) { return parseInt(hex.length % 2 ? l + l : l, 16) }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
        },
        setActiveDay(index) {
            this.activeDayIndex = index;
        },
        convertingDate(date) {
            if (moment().format('MMM') == moment(date).format("MMM")) {
                this.activeMonthClass.push('item');
                return moment(date).format("ddd DD");
            }
            else {
                this.activeMonthClass.push('item item-next-month');
                return moment(date).format("MMM DD");
            }

        },
        pickedDate(pickedDate) {
            this.$emit('datePicked', pickedDate);
            const items = document.querySelectorAll(".item");
            items.forEach(item => {
                item.classList.remove("active");
            });
            event.target.closest("li.item").classList.add("active");
        },

        takingAction() {
            axios.get('/api/appointment?user_id=' + localStorage.getItem('usertoken'), {
                headers: {
                    "Accept": "application/json"
                },
            }).then((resp) => {
                this.todayDateClicked = resp.data['todayData'][0].dateRecord;
                this.today_date = moment(resp.data['todayData'][0].dateRecord).format("dddd MMM DD");
                this.totalTodayAppoitment = resp.data['todayData'][0].appointmentCount;
                this.otherAppointmentData = resp.data['otherDayData'];
            });

        }

    },

}
</script>
<style scoped>
.calender-carousel-horizontal {
    display: flex;
    overflow-x: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
}

.calender-carousel-horizontal .item {
    flex: 0 0 auto;
    margin-right: 10px;
}

.calender-carousel-horizontal .item button {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #fff;
    text-align: center;
}

.calender-carousel-horizontal .item button.active {
    color: #fff;
}

.calender-carousel-horizontal .item-next-month button {
    background: #eaeaea;
    /* opacity: 20%; */
}

.calender-carousel-horizontal .item-next-month h5 {
    font-weight: bold;
}

.calender-listing ul li.item.active .calendar-btn {
    background-color: var(--button-background);
    border: 1px solid var(--colorA) !important;
    text-align: left;
}
</style>