<template>
  <div class="d-flex flex-column flex-root">
    <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">
      <div class="login-aside d-flex flex-column flex-row-auto">
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15 margintop">
          <!--begin::Aside header-->
          <a href="#" class="text-center mb-10">
            <h3 class="text-center mt-2 mb-5">
              <!-- <img src="images/logo.svg" alt="Logo" /> -->
              <img :src="logoImage" alt="Logo" />
            </h3>
          </a>
          <!--end::Aside header-->
          <!--begin::Aside title-->
          <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg orange-color-api">
            Discover Amazing Tool <br />
          </h3>
          <div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"></div>
          <!--end::Aside title-->
        </div>
      </div>
      <div class="container">
        <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
          <div class="d-flex flex-column-fluid flex-center justify-content-center">
            <div class="p-5">

              <div class="col-md-12">

                <form v-on:submit.prevent="register_user">
                  <div class="pb-13 pt-lg-0 pt-5">
                    <h3 class="font-weight-bolder orange-color-api font-size-h4 font-size-h1-lg orange-color-api">
                      Welcome to {{ businessName }}
                    </h3>

                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput2" class="font-size-h6 font-weight-bolder text-dark-api">Full
                      Name</label>
                    <input type="text" name="name" id="name" class="form-control form-control-solid h-auto rounded-lg"
                      aria-describedby="nameHelp" placeholder="Enter Your Name" v-model="form.name" />
                    <span class="text-danger">{{ notifmsg.name }}</span>
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput2"
                      class="font-size-h6 font-weight-bolder text-dark-api">Email</label>
                    <input type="email" name="email" id="email" class="form-control form-control-solid h-auto rounded-lg"
                      aria-describedby="emailHelp" placeholder="Enter Email" v-model="form.email" />
                    <span class="text-danger">{{ notifmsg.email }}</span>
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput3"
                      class="font-size-h6 font-weight-bolder text-dark-api">Password</label>
                    <input type="password" name="password" id="password"
                      class="form-control form-control-solid h-auto rounded-lg" aria-describedby="passHelp"
                      placeholder="Enter Password" v-model="form.password" />
                    <span class="text-danger">{{ notifmsg.password }}</span>
                  </div>

                  <div class="mb-3">
                    <label for="exampleFormControlInput3" class="font-size-h6 font-weight-bolder text-dark-api">Confirm
                      Password</label>
                    <input type="password" name="password_confirmation" id="password_confirmation"
                      class="form-control form-control-solid h-auto rounded-lg"
                      aria-describedby="password_confirmationHelp" placeholder="Enter Confirm Password"
                      v-model="form.password_confirmation" />
                    <span class="text-danger">{{ notifmsg.password_confirmation }}</span>
                  </div>

                  <button type="submit" class="button-sized btn btn-primary">
                    Sign Up
                  </button>
                </form>
                <div class="mt-4">
                  <span class="text-dark-api font-weight-bold font-size-h4">
                    Already a User?
                    <a href="/" class="text-primary-green font-weight-bolder green-color-api">Login</a></span>
                  <div class="d-flex justify-content-center links">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import router from '../router';
export default {
  data() {
    return {
      notifmsg: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      form: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      appointmentForm: {

      },
      logoImage: '',
      businessName: "",
    };
  },
  created() {
    axios
      .get('/api/business-setting')
      .then((resp) => {
        if (resp.data) {
          this.logoImage = resp.data.businessetting[0].headerLogoUrl;
          let setting = resp.data.businessetting[0];
          let settingTitle = resp.data.businessdata[0];
          const root = document.querySelector(':root');
          if (setting.colorA)
            root.style.setProperty('--colorA', setting.colorA);
          if (setting.colorB)
            root.style.setProperty('--colorB', setting.colorB);
          if (setting.colorC)
            root.style.setProperty('--colorC', setting.colorC);
          if (setting.colorD)
            root.style.setProperty('--colorD', setting.colorD);
          if (setting.headingFont)
            root.style.setProperty('--font-heading', setting.headingFont);
          if (setting.bodyFont)
            root.style.setProperty('--font-body', setting.bodyFont);
          if (settingTitle.business_name)
            this.businessName = settingTitle.business_name;
          root.style.setProperty('--bussiness-name', settingTitle.business_name);

        } else {
          Swal.fire({
            title: 'Oops',
            text: 'No Website found',
            icon: 'warning',
          });
        }
      })
      .catch((e) => {
        this.notifmsg = e.response.data;
      });

  },
  methods: {

    register_user() {

      this.notifmsg.name = '';
      this.notifmsg.email = '';
      this.notifmsg.password = '';
      this.notifmsg.password_confirmation = '';

      if (this.form.name.trim() === '') {
        this.notifmsg.name = 'Please Enter Your Name.';
      }
      if (this.form.email.trim() === '') {
        this.notifmsg.email = 'Please Enter Your Email.';
      }
      if (this.form.password.trim() === '') {
        this.notifmsg.password = 'Please Enter Your Password.';
      }
      if (this.form.password_confirmation.trim() === '') {
        this.notifmsg.password_confirmation = 'Please Enter Your Confirm Password.';
      }

      // check if there are any errors
      if (
        this.notifmsg.name === '' &&
        this.notifmsg.email === '' &&
        this.notifmsg.password === '' &&
        this.notifmsg.password_confirmation === ''
      ) {

        axios
          .post('/api/register', this.form)
          .then((resp) => {
            this.form.name = '';
            this.form.email = '';
            this.form.password = '';
            this.form.password_confirmation = '';
            console.log('resp', resp['data']);
            if (resp['data']['user']) {
              localStorage.setItem('usertoken', resp['data']['user']);
              this.email = '';
              this.password = '';
              window.location.href = "/dashboard";
              //router.push({ name: 'dashboard' });
              Swal.fire({
                title: '',
                text: 'Your Account has been successfully registered.',
                icon: 'success',
              });
            } else {
              Swal.fire({
                title: 'Oops',
                text: 'error',
                icon: 'warning',
              });
            }
          })
          .catch((e) => {
            Swal.fire({
              title: 'Oops',
              text: e.response.data,
              icon: 'warning',
            });
            this.notifmsg = e.response.data;
          });
      }
    },
  },
};
</script>
<style scoped>
@media (min-width: 992px) and (max-width: 1399.98px) {
  .login.login-1 .login-aside {
    width: 100%;
    max-width: 450px;
  }

  .pt-lg-40,
  .py-lg-40 {
    padding-top: 10rem !important;
  }
}

.form-control.form-control-solid {
  background-color: #ebedf3;
  border-color: #ebedf3;

  padding: 15px 0px 15px 10px;
  color: #3f4254;
  /* -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease; */
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}

input#exampleInputEmail1 {
  width: 370px;
}

.rounded-lg {
  border-radius: 0.85rem !important;
}

.button-sized {
  padding: 15px 25px 15px 25px;
  border-radius: 8px !important;
  font-weight: 700;
  background-color: var(--colorA) !important;
  border: 1px solid var(--colorA);
}

.font-size-h6 {
  font-size: 1.175rem !important;
}

.login.login-1.login-signin-on.d-flex.flex-column.flex-lg-row.flex-column-fluid.bg-white {
  height: 100vh !important;
}

.login-aside.d-flex.flex-column.flex-row-auto {
  background-color: var(--colorA) !important;
}
</style>