<template>

    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button v-if="showSellGiftCard || showCustomerGiftCard" type="button" @click=closeSellModal
                    class="btn-close-svg" data-bs-dismiss="modal" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7.905" height="7.905" viewBox="0 0 7.905 7.905">
                        <g id="Group_1469" data-name="Group 1469" transform="translate(-1480.904 -467.983)">
                            <line id="Line_340" data-name="Line 340" x1="5.784" y2="5.784"
                                transform="translate(1481.965 469.043)" fill="none" stroke="#d5a948"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                            <line id="Line_341" data-name="Line 341" x1="5.784" y1="5.784"
                                transform="translate(1481.965 469.043)" fill="none" stroke="#d5a948"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        </g>
                    </svg>
                </button>
                <div class="heading">
                    <h3>
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="22px"
                            viewBox="0 0 33.54 21.66">
                            <path class="cls-1"
                                d="m3.19,0h27.17c.88,0,1.67.36,2.25.93.58.58.93,1.37.93,2.25v15.29c0,.88-.36,1.67-.93,2.25-.58.58-1.37.93-2.25.93H10.14s-.09.01-.13.01c-.05,0-.09,0-.13-.01H3.19c-.88,0-1.67-.36-2.25-.93C.36,20.14,0,19.34,0,18.47v-7.68s0-.05,0-.07,0-.05,0-.07V3.18C0,2.31.36,1.51.94.94c.58-.58,1.37-.94,2.25-.94h0Zm8.59,8.46c-.05.11-.11.26-.18.43l-.17.42c-.05.12-.1.26-.16.4,2.38-.51,3.64-1.29,4.17-2.08.19-.29.28-.57.27-.82,0-.25-.08-.49-.22-.7-.29-.44-.8-.75-1.33-.74-1.1,0-1.72,1.5-2.19,2.65-.07.18-.14.35-.18.44h0Zm-1.02-1.7c.63-1.44,1.51-2.99,3.4-2.99,1.08,0,2.1.59,2.67,1.47.29.46.47.99.48,1.56,0,.56-.16,1.15-.54,1.72-.34.5-.86,1-1.6,1.44h16.87V3.18c0-.46-.19-.88-.49-1.18s-.72-.49-1.18-.49H10.76v5.25h0Zm-5.94,3.2c-.73-.44-1.25-.93-1.59-1.43-.38-.57-.55-1.16-.54-1.72,0-.57.18-1.1.48-1.56.57-.88,1.6-1.47,2.67-1.47,1.9,0,2.78,1.57,3.41,3.02V1.51H3.19c-.46,0-.88.19-1.18.49-.3.3-.49.72-.49,1.18v6.78h3.31Zm3.58-1.07c-.07-.17-.13-.31-.18-.43-.04-.09-.11-.26-.18-.44-.47-1.15-1.09-2.65-2.19-2.66-.53,0-1.05.3-1.33.74-.14.21-.22.45-.22.7,0,.26.08.54.27.82.53.79,1.79,1.57,4.17,2.08l-.16-.4-.17-.42h0Zm-4.81,4.45c1.2.01,2.3-.2,3.26-.69.58-.29,1.11-.69,1.6-1.18H1.51v7c0,.46.19.88.49,1.18.3.3.72.49,1.18.49h6.06v-7.23c-.52.47-1.08.86-1.69,1.17-1.2.61-2.54.88-4,.86l.02-1.59h0Zm8.18-1.88c.47.43.98.79,1.53,1.1.99.55,2.1.9,3.34,1.01l-.14,1.59c-1.48-.13-2.8-.55-3.98-1.21-.63-.35-1.21-.77-1.75-1.25v7.43h19.6c.46,0,.88-.19,1.18-.49s.49-.72.49-1.18v-7H11.76Z" />
                        </svg>
                        Gift
                    </h3>
                </div>
                <div v-if="showSellGiftCard">
                    <SellGiftCard @close="closeChildComponent" @reloadCards="fetchTransaction"
                        :giftcodeData="giftcodeData" :giftcardObject="giftcardObject"></SellGiftCard>
                </div>
                <div v-if="showCustomerGiftCard">
                    <div class="white-yellow-box">
                        <div class="yellow-box d-flex align-items-center">
                            <div class="me-auto">
                                <p>Total</p>
                                <h4>$ {{ totalAmount.toFixed(2) }}</h4>
                            </div>
                            <div class="form-group ms-auto">
                                <select @change="filterTransactionByMonth" v-model="monthIndex" class="form-control">
                                    <option selected value="all_time">All time</option>
                                    <option v-for="(month, index) in monthsFiltered" :key="index" :value="index + 1">{{
                                        month }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="white-box">
                            <ul class="d-flex justify-content-between">
                                <li>
                                    <p>E-Gift</p>
                                    <h5>${{ eGiftAmount }}.00 ({{ eGiftCount }})</h5>
                                </li>
                                <li>
                                    <p class="float-right">Giftcard</p>
                                    <h5>${{ physicalGiftAmount }}.00 ({{ physicalGiftCount }})</h5>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="last-activity-list mt-5">
                        <div v-for="(transaction, index) in transactions" @click="showHistory(transaction.cardId)"
                            :key="index" class="transaction box egift-box d-flex radius-5 list-gift"
                            :class="{ active: transaction.reedeemMessage == 'Reset by' }">
                            <div class="d-flex details my-2">
                                <p :class="{ 'color-D': transaction.reedeemMessage == 'Reset by' }">{{
                                    transaction.customerName }}</p>
                                <div class="d-flex justify-content-between my-2">
                                    <span style="font-size: 13px;margin-top: 3px;" class="pr-2">{{ transaction.cardType
                                        + " " +
                                        transaction.reedeemMessage
                                        }}</span>
                                    <p :class="{ 'color-D': transaction.reedeemMessage == 'Reset by' }">{{
                                        transaction.soldBy }}</p>
                                </div>
                            </div>
                            <div class="amount" style="display: flex; flex-direction: column; align-items: flex-end;">
                                <p
                                    :class="{ 'color-yellow': transaction.reedeemType !== 'redeem_by', 'color-D': transaction.reedeemMessage == 'Reset by' }">
                                    {{ transaction.reedeemType == "redeem_by" ? "-" + transaction.amount
                                        : transaction.amount || 0 }}.00
                                </p>
                                <p :class="{ 'color-D': transaction.reedeemMessage == 'Reset by' }">{{ transaction.date
                                    }}</p>
                            </div>
                        </div>
                        <div class="modal-btns text-center" @click="initLiveScanner">
                            <div class="remove-category theme-btn">
                                <div class="input-container btn-gift-card">
                                    <label class="svg-size" style="height: 14px;">
                                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 33.54 21.66">
                                            <path class="cls-1"
                                                d="m3.19,0h27.17c.88,0,1.67.36,2.25.93.58.58.93,1.37.93,2.25v15.29c0,.88-.36,1.67-.93,2.25-.58.58-1.37.93-2.25.93H10.14s-.09.01-.13.01c-.05,0-.09,0-.13-.01H3.19c-.88,0-1.67-.36-2.25-.93C.36,20.14,0,19.34,0,18.47v-7.68s0-.05,0-.07,0-.05,0-.07V3.18C0,2.31.36,1.51.94.94c.58-.58,1.37-.94,2.25-.94h0Zm8.59,8.46c-.05.11-.11.26-.18.43l-.17.42c-.05.12-.1.26-.16.4,2.38-.51,3.64-1.29,4.17-2.08.19-.29.28-.57.27-.82,0-.25-.08-.49-.22-.7-.29-.44-.8-.75-1.33-.74-1.1,0-1.72,1.5-2.19,2.65-.07.18-.14.35-.18.44h0Zm-1.02-1.7c.63-1.44,1.51-2.99,3.4-2.99,1.08,0,2.1.59,2.67,1.47.29.46.47.99.48,1.56,0,.56-.16,1.15-.54,1.72-.34.5-.86,1-1.6,1.44h16.87V3.18c0-.46-.19-.88-.49-1.18s-.72-.49-1.18-.49H10.76v5.25h0Zm-5.94,3.2c-.73-.44-1.25-.93-1.59-1.43-.38-.57-.55-1.16-.54-1.72,0-.57.18-1.1.48-1.56.57-.88,1.6-1.47,2.67-1.47,1.9,0,2.78,1.57,3.41,3.02V1.51H3.19c-.46,0-.88.19-1.18.49-.3.3-.49.72-.49,1.18v6.78h3.31Zm3.58-1.07c-.07-.17-.13-.31-.18-.43-.04-.09-.11-.26-.18-.44-.47-1.15-1.09-2.65-2.19-2.66-.53,0-1.05.3-1.33.74-.14.21-.22.45-.22.7,0,.26.08.54.27.82.53.79,1.79,1.57,4.17,2.08l-.16-.4-.17-.42h0Zm-4.81,4.45c1.2.01,2.3-.2,3.26-.69.58-.29,1.11-.69,1.6-1.18H1.51v7c0,.46.19.88.49,1.18.3.3.72.49,1.18.49h6.06v-7.23c-.52.47-1.08.86-1.69,1.17-1.2.61-2.54.88-4,.86l.02-1.59h0Zm8.18-1.88c.47.43.98.79,1.53,1.1.99.55,2.1.9,3.34,1.01l-.14,1.59c-1.48-.13-2.8-.55-3.98-1.21-.63-.35-1.21-.77-1.75-1.25v7.43h19.6c.46,0,.88-.19,1.18-.49s.49-.72.49-1.18v-7H11.76Z" />
                                        </svg>
                                    </label>
                                    <button class="cash_amount_paid gift-card color-yellow"
                                        @click="showAfterScan">Scan</button>

                                    <div class="circle-image plus-gift-card" style="width:39px">
                                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 28.36 29.01">
                                            <g>
                                                <path class="cls-1" d="m8.12,7.68h1.48v13.65h-1.48V7.68Z" />
                                                <path class="cls-1" d="m11.85,21.32V7.68h1.48v13.64h-1.48Z" />
                                                <path class="cls-1" d="m22.66,7.68h1.48v13.65h-1.48V7.68Z" />
                                                <path class="cls-1" d="m18.76,7.68h.94v13.65h-.94V7.68Z" />
                                                <path class="cls-1" d="m6.21,21.33h-.44V7.68h.44v13.65Z" />
                                                <path class="cls-1" d="m4.66,21.33h-.44V7.68h.44v13.65Z" />
                                                <path class="cls-1" d="m7.04,7.68h.44v13.64h-.44V7.68Z" />
                                                <path class="cls-1" d="m14.13,21.33h-.43V7.68h.43v13.65Z" />
                                                <path class="cls-1" d="m16.18,7.68h.43v13.65h-.43V7.68Z" />
                                                <path class="cls-1" d="m20.31,7.68h.44v13.66h-.44V7.68Z" />
                                                <path class="cls-1" d="m21.58,7.68h.44v13.65h-.44V7.68Z" />
                                                <path class="cls-1" d="m11.39,21.33h-.39V7.68h.39v13.65Z" />
                                                <path class="cls-1" d="m14.97,7.68h.42v13.65h-.42V7.68Z" />
                                                <path class="cls-1" d="m17.81,21.33h-.43V7.68h.43v13.65Z" />
                                                <path class="cls-1" d="m10.01,7.68h.38v13.65h-.38V7.68Z" />
                                            </g>
                                            <g>
                                                <path class="cls-1" d="m1,7.07H0V.5C0,.22.22,0,.5,0h6.57v1H1v6.07Z" />
                                                <path class="cls-1"
                                                    d="m7.07,29.01H.5c-.28,0-.5-.22-.5-.5v-6.57h1v6.07h6.07v1Z" />
                                                <path class="cls-1"
                                                    d="m27.86,29.01h-6.57v-1h6.07v-6.07h1v6.57c0,.28-.22.5-.5.5Z" />
                                                <path class="cls-1"
                                                    d="m28.36,7.07h-1V1h-6.07V0h6.57c.28,0,.5.22.5.5v6.57Z" />
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div v-show="showVideoStream" ref="scanner" id="scannerM"
                                    :class="{ 'scanner-container': true, 'd-none': isScannerHidden }">
                                    <div>
                                        <button @click="closeCam()" class="btn btn-danger">Close scanner</button>
                                    </div>
                                    <video class="for-mobile" autoplay="true" preload="auto" src="" muted="true"
                                        playsinline="true"
                                        style="width: 100%; height: 161px; max-width: 100%; border: 1px solid #ccc;"></video>
                                    <canvas class="drawingBuffer" style="display: none;" width="4" height="4"></canvas>
                                    <br clear="all">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-if="showGiftCardHistory">
                    <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                        <div v-if="giftCardType == 'Egift'" class="gift-voucher__content mb-3">
                            EGift details
                        </div>
                        <div v-else class="gift-voucher__content mb-3">
                            Gift details
                        </div>
                        <div class="gift-voucher__content mb-3">
                            {{ giftCreatedDate }}
                        </div>
                    </div>
                    <div class="gift-voucherheader" style="height: 250px;">
                        <img :src="giftCardImageUrl" alt="LUV Nails Logo" class="gift-voucher__header__logo" />
                        <div class="last-activity-list">
                            <div class="transaction box egift-box d-flex" style="height: 80px;">
                                <div class="d-flex details">
                                    <p>Giftcard </p>
                                    <div class="d-flex justify-content-between my-1">
                                        <p class="color-yellow">{{ giftCardCode ? giftCardCode : "ABCD" }}</p>
                                    </div>
                                </div>
                                <div class="amount">
                                    <p class="my-1" style="margin-top: 0px !important;">Balance</p>
                                    <p class="mt-1">{{ giftCardDetail.remaining_amount ? giftCardDetail.remaining_amount
                                        : 0 }} $</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="last-activity-list mt-4">
                        <div class="transaction box egift-box d-flex radius-8 active" style="height: 75px;">
                            <div class="d-flex details">
                                <span>Buyer </span>
                                <p>{{ giftCardDetail.b_first_name + " " + giftCardDetail.b_last_name }}</p>
                            </div>
                            <div class="">
                                <span class="">Phone</span>
                                <p>{{ giftCardDetail.b_contact_no }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="last-activity-list mt-3">
                        <div class="mb-3" v-for="(transaction, index) in giftCardHistory" :key="index">
                            <div v-if="transaction.type !== 'reset_by'"
                                class="transaction box egift-box d-flex radius-5"
                                style="height: 90px; padding: 8px !important;">

                                <div class="d-flex details">
                                    <img :src="giftCardImageUrl" alt="LUV Nails Logo" class="gift-final-sec" />
                                </div>
                                <div class="d-flex details mr-2">
                                    <span>{{ formateDate(transaction.created_at) }}</span>
                                    <p class="pt-1 gift-card-elips" style="width: 121px;"
                                        :title="transaction.customer_name">{{ transaction.customer_name }}</p>
                                    <span class="pt-1">{{ getRedeemMessage(transaction.type) }}</span>
                                </div>
                                <div class="amount pr-1" style="margin-top: 20px; width: 80px;">
                                    <p class="float-right"
                                        :class="{ 'color-yellow': transaction.type !== 'redeem_by' }">{{
                                            transaction.type == 'redeem_by' ? "-" + transaction.amount : transaction.amount
                                        }}.00
                                    </p>
                                    <p class="gift-card-elips" :title="transaction.user_details.name">{{
                                        transaction.user_details.name }}</p>
                                </div>

                            </div>


                            <div style="height: 45px; padding: 8px !important;" v-else
                                class="transaction box egift-box d-flex align-items-center radius-5 active">
                                <span class="ml-2 pt-1 color-D">Reset card by</span>
                                <p class="color-D" style="margin-right:67px">{{ transaction.user_details.name }}</p>
                                <span class="ml-2 color-D">{{ formateDate(transaction.created_at) }}</span>
                            </div>

                        </div>
                    </div>
                    <div v-if="currentCardStatus !== 'reset_by' || giftCardType == !'Egift'" class="modal-btns d-flex ">
                        <span v-if="giftCardType == 'Gift'" class="theme-btn white-btn"
                            @click="closeSellModal">Back</span>
                        <button v-if="giftCardType == 'Gift'" type="submit" class="theme-btn yellow-btn"
                            @click="resetThisGift(giftCardDetail.e_gift_id)">Reset
                            this Gift</button>
                        <span v-else class="theme-btn white-btn center mx-auto" @click="closeSellModal">Back</span>
                    </div>
                    <div v-else class="modal-btns d-flex ">
                        <span class="theme-btn white-btn center mx-auto" @click="closeSellModal">Back</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import Quagga from 'quagga';
import SellGiftCard from './SellGiftCard.vue';
import axios from 'axios';
export default {
    components: {
        SellGiftCard
    },
    data() {
        return {
            totalAmount: 0,
            monthIndex: 'all_time',
            monthsFiltered: '',
            eGiftAmount: 0,
            eGiftCount: 0,
            physicalGiftAmount: 0,
            physicalGiftCount: 0,
            transactions: [],
            scannedCode: null,
            showVideoStream: false,
            giftcodeData: "",
            showSellGiftCard: false,
            showCustomerGiftCard: true,
            giftCardDetail: {},
            Balance: 0,
            giftCardHistory: [],
            showGiftCardHistory: false,
            giftCardCode: "",
            currentCardStatus: "",
            giftCreatedDate: "",
            giftCardImageUrl: "",
            isScannerHidden: false
        }
    },
    mounted() {
        this.fetchMonth()
        this.fetchTransaction(this.monthIndex)
    },
    methods: {
        fetchMonth() {
            const currentDate = new Date();
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            const currentMonthIndex = currentDate.getMonth();
            this.monthsFiltered = monthNames.slice(0, currentMonthIndex + 1)
        },
        resetThisGift(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'This action will reset the gift. Do you want to continue?',
                showCancelButton: true,
                confirmButtonText: '<i class="fa fa-check"></i>',
                cancelButtonText: '<i class="fa fa-times"></i>'
            }).then((result) => {
                if (result.isConfirmed) {
                    let formdata = new FormData();
                    formdata.append('gift_id', id);
                    formdata.append('user_id', localStorage.getItem("usertoken"));
                    axios.post('api/giftCartResetById', formdata)
                        .then((response) => {
                            Swal.fire({
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true
                            });
                        })
                        .catch(() => {
                            Swal.fire({


                                text: 'Something went wrong!',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true
                            });
                        });
                }
            });
        },
        formateDate(date) {
            const options = { year: 'numeric', month: 'short', day: '2-digit' };
            return new Date(date).toLocaleDateString('en-US', options)
        },
        showHistory(id) {
            let formdata = new FormData();
            formdata.append('card_id', id);
            formdata.append('user_id', localStorage.getItem('usertoken'));

            axios.post('api/giftCartDetailById', formdata)
                .then((response) => {
                    if (response.data.status === 1) {
                        const data = response.data.data;
                        this.giftCardDetail = data.customerCard;
                        this.currentCardStatus = data.latestStatus.type;
                        this.giftCardHistory = data.historyList;
                        // console.log("this.giftCardHistory", this.giftCardHistory);
                        this.giftCardType = data.latestStatus ? data.latestStatus.card_type : "";
                        if (data.card_data) {
                            this.giftCardCode = data.card_data.gift_code || "";
                            this.giftCreatedDate = this.formateDate(data.card_data.created_at);
                            this.giftCardImageUrl = data.card_data.image_url ? data.card_data.image_url : 'images/gift-card.jpg';
                        } else {
                            this.giftCardCode = "";
                            this.giftCreatedDate = "";
                            this.giftCardImageUrl = 'images/gift-card.jpg';
                        }

                        this.$emit('setGiftDetails', response.data);
                        this.showCustomerGiftCard = false;
                        this.showSellGiftCard = false;
                        this.showGiftCardHistory = true;
                    } else {
                        console.error("Error in response status:", response.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    Swal.fire({
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    });
                });
        },

        closeSellModal() {
            this.showSellGiftCard = false;
            this.showGiftCardHistory = false;
            this.showCustomerGiftCard = true;
        },
        closeChildComponent() {
            this.closeSellModal()
        },
        decodeBarcode(file) {
            Quagga.decodeSingle(
                {
                    decoder: {
                        readers: ['code_128_reader'],
                    },
                    locate: true,
                    src: URL.createObjectURL(file),
                },
                (result) => {
                    if (result && result.codeResult && result.codeResult.code) {
                        this.scannedCode = result.codeResult.code;

                    } else {
                        Swal.fire({
                            text: 'No code found!',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        });
                    }
                }
            );
        },

        initLiveScanner() {
            this.showVideoStream = true;
            if (this.isMobile()) {
                // window.location.href = '/mobile-scanner'; // Replace '/mobile-scanner' with your mobile scanner route

                if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
                        .then(stream => {
                            const video = document.createElement('video');
                            this.$refs.scanner.appendChild(video);
                            video.srcObject = stream;
                            video.autoplay = true;

                            Quagga.init({
                                inputStream: {
                                    name: 'Live',
                                    type: 'LiveStream',
                                    target: video,

                                },
                                decoder: {
                                    readers: ['code_128_reader']
                                }
                            }, (err) => {
                                if (err) {
                                    console.error('Quagga initialization failed: ', err);
                                    return;
                                }
                                Quagga.start();
                                Quagga.onDetected(this.handleDetected);
                            });
                        })
                        .catch(err => {
                            console.error('Error accessing camera:', err);
                        });
                }
            } else {

                navigator.mediaDevices
                    .getUserMedia({ video: true })
                    .then((stream) => {
                        Quagga.init(
                            {
                                inputStream: {
                                    name: 'Live',
                                    type: 'LiveStream',
                                    target: this.$refs.scanner,
                                    constraints: {
                                        width: 400,
                                        height: 400,
                                        facingMode: 'environment',
                                        aspectRatio: { min: 1, max: 2 },
                                    },
                                    area: {
                                        top: '0%',
                                        right: '0%',
                                        left: '0%',
                                        bottom: '0%',
                                    },
                                },
                                decoder: {
                                    readers: ['code_128_reader'],
                                },
                            },
                            (err) => {
                                if (err) {
                                    console.error(err);
                                    return;
                                }
                                Quagga.start();
                            }
                        );

                        Quagga.onDetected(this.handleDetected);
                    })
                    .catch((err) => {
                        console.error('Error accessing camera:', err);
                        Swal.fire({
                            text: 'Camera Not Found',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        });
                    });
            }
        },
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },

        handleDetected(result) {
            if (result && result.codeResult && result.codeResult.code) {
                this.scannedCode = result.codeResult.code;

                Quagga.stop()
                this.openSellGiftCardModal()
                this.showVideoStream = false
                this.closeCam()
            } else {
                this.scannedCode = 'No valid barcode found';
            }
            this.closeCam()
        },
        showAfterScan() {
            this.isScannerHidden = false;
        },
        closeCam() {
            this.isScannerHidden = true;
            this.showVideoStream = false;
            Quagga.offDetected(this.handleDetected);
            Quagga.stop();
        },
        openSellGiftCardModal() {
            const user_id = localStorage.getItem('usertoken')
            try {
                axios.post("api/create-gift-card", { user_id: user_id, bar_code_id: this.scannedCode }).then(response => {
                    if (response.status = 200) {
                        this.giftcodeData = response.data.giftcode
                        this.giftcardObject = response.data.giftcard
                        this.showSellGiftCard = true;
                        this.showCustomerGiftCard = false;
                    }

                }).catch(error => {

                    Swal.fire({
                        text: "Duplicate Barcode",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    })
                })
            }
            catch (err) {
                alert("Please select a gift card to sell.")
            }
        },

        fetchTransaction(month) {
            if (month == undefined) {
                month = 'all_time'
            }
            let formdata = new FormData();
            formdata.append('month', month)
            formdata.append('user_id', localStorage.getItem('usertoken'))
            axios.post('api/filterSoldGift', formdata)
                .then((response) => {
                    this.totalAmount = response.data.data.total_amount
                    this.eGiftAmount = response.data.data.e_gift.gift_total_amount
                    this.eGiftCount = response.data.data.e_gift.total
                    this.physicalGiftAmount = response.data.data.physical.gift_total_amount
                    this.physicalGiftCount = response.data.data.physical.total
                    const transactions = response.data.data.historyData
                    const options = { month: 'short', day: '2-digit' };
                    this.transactions = transactions.map(transaction => ({
                        id: transaction.id,
                        customerName: transaction.customer_name,
                        amount: transaction.amount,
                        soldBy: transaction.user_details.name,
                        isActive: transaction.amount == 0 ? true : false,
                        date: new Date(transaction.date).toLocaleDateString('en-US', options),
                        cardId: transaction.card_id,
                        reedeemMessage: this.getRedeemMessage(transaction.type),
                        reedeemType: transaction.type,
                        cardType: transaction.card_type
                    }));
                })
                .catch((error) => {
                    //  console.error('Error fetching transactions:', error);
                });
        },

        getRedeemMessage(gift) {
            if (gift === 'gift_sold' || gift === 'egift_sold') {
                return 'Sold by';
            } else if (gift === 'redeem_by') {
                return 'Redeem by';
            } else if (gift === 'reset_by') {
                return 'Reset by';
            } else if (gift === 'sold_online') {
                return 'Sold Online';
            } else {
                return '';
            }
        },
        filterTransactionByMonth(days) {
            this.fetchTransaction(this.monthIndex)
        },

    },
    beforeUnmount() {
        Quagga.stop();
    },
}

</script>