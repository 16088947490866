<template>
    <div>
        <div class="mt-4">
            <a href="#" class="closeiCoSecond" @click="openModelClosedS">
                <svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 492 492"
                    xml:space="preserve">
                    <g>
                        <g>
                            <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872
                      c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872
                      c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052
                      L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116
                      c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952
                      c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116
                      c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z" />
                        </g>
                    </g>
                </svg>
            </a>
            <div class="container">
                <div class="row">

                    <div class="phone-number-input">
                        <div class="d-flex justify-content-center align-items-center">
                            <label class="pr-3 font-sizeW-18">To</label>
                            <input type="hidden" id="mobile_code">

                            <input ref="phoneNumberInput" type="tel" class="form-control tel" v-model="telNumberSecond"
                                :style="inputStyle" placeholder="Phone Number" required />
                            <label class="pl-3 delete-back" @click="backRemove()">
                                <svg width="25px" height="20px" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
                                    <path
                                        d="M12.0004 9.5L17.0004 14.5M17.0004 9.5L12.0004 14.5M4.50823 13.9546L7.43966 17.7546C7.79218 18.2115 7.96843 18.44 8.18975 18.6047C8.38579 18.7505 8.6069 18.8592 8.84212 18.9253C9.10766 19 9.39623 19 9.97336 19H17.8004C18.9205 19 19.4806 19 19.9084 18.782C20.2847 18.5903 20.5907 18.2843 20.7824 17.908C21.0004 17.4802 21.0004 16.9201 21.0004 15.8V8.2C21.0004 7.0799 21.0004 6.51984 20.7824 6.09202C20.5907 5.71569 20.2847 5.40973 19.9084 5.21799C19.4806 5 18.9205 5 17.8004 5H9.97336C9.39623 5 9.10766 5 8.84212 5.07467C8.6069 5.14081 8.38579 5.2495 8.18975 5.39534C7.96843 5.55998 7.79218 5.78846 7.43966 6.24543L4.50823 10.0454C3.96863 10.7449 3.69883 11.0947 3.59505 11.4804C3.50347 11.8207 3.50347 12.1793 3.59505 12.5196C3.69883 12.9053 3.96863 13.2551 4.50823 13.9546Z"
                                        stroke="#000000" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </label>
                        </div>


                        <div v-if="forCallingStatus"
                            class="d-flex justify-content-between mt-3 py-1 px-2 bgcolor-yellow color-D radius-5"
                            id="addBgColor">
                            <span class="font-sizeW-18" style=" display: block; font-weight: 100; margin-right: 0;"
                                id="NewcallCalling">Calling</span>
                            <span class="font-sizeW-18" style=" display: block; font-weight: 100; margin-left: 0;"
                                id="NewcallDuration">00:00:00</span>
                        </div>
                        <div class="col-md-12 col-md-offset-4 phone">
                            <div class="num-pad">
                                <div v-for="(row, index) in numPadRows" :key="index"
                                    class="d-flex justify-content-center">
                                    <div v-for="num in row" :key="num" class="span4">
                                        <div class="num" @click="handleNumberClick(num)">
                                            <div class="txt">{{ num }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="clear-flexed col-md-5 mx-auto">
                                <button v-if="!showHangupButton" class="btn btn-success btn-block flatbtn"
                                    @click="MakeCallD">
                                    <svg width="20px" height="20px" viewBox="0 0 15 15" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="Dribbble-Light-Preview"
                                                transform="translate(-103.000000, -7321.000000)" fill="#000000">
                                                <g id="icons" transform="translate(56.000000, 160.000000)">
                                                    <path
                                                        d="M61.7302966,7173.99596 C61.2672966,7175.40296 59.4532966,7176.10496 58.1572966,7175.98796 C56.3872966,7175.82796 54.4612966,7174.88896 52.9992966,7173.85496 C50.8502966,7172.33496 48.8372966,7169.98396 47.6642966,7167.48896 C46.8352966,7165.72596 46.6492966,7163.55796 47.8822966,7161.95096 C48.3382966,7161.35696 48.8312966,7161.03996 49.5722966,7161.00296 C50.6002966,7160.95296 50.7442966,7161.54096 51.0972966,7162.45696 C51.3602966,7163.14196 51.7112966,7163.84096 51.9072966,7164.55096 C52.2742966,7165.87596 50.9912966,7165.93096 50.8292966,7167.01396 C50.7282966,7167.69696 51.5562966,7168.61296 51.9302966,7169.09996 C52.6632966,7170.05396 53.5442966,7170.87696 54.5382966,7171.50296 C55.1072966,7171.86196 56.0262966,7172.50896 56.6782966,7172.15196 C57.6822966,7171.60196 57.5872966,7169.90896 58.9912966,7170.48196 C59.7182966,7170.77796 60.4222966,7171.20496 61.1162966,7171.57896 C62.1892966,7172.15596 62.1392966,7172.75396 61.7302966,7173.99596 C61.4242966,7174.92396 62.0362966,7173.06796 61.7302966,7173.99596"
                                                        id="call-icon"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                                <button v-if="showHangupButton"
                                    class="btn btn-success btn-block flatbtn spaniconss inboundAfterAnswer bgcolor-yellow"
                                    id="outboundHangupSecond" @click="hangupCallSecond">
                                    <svg width="20px" height="20px" version="1.1" id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                                        xml:space="preserve">
                                        <g>
                                            <path
                                                d="M478.1,270.1c3.5,24.4,5.8,57.7-5.1,70.5c-18.1,21.1-132.6,21.1-132.6-21.1c0-21.3,18.8-35.2,0.8-56.3
                                            C323.4,242.4,291.5,242,256,242s-67.3,0.3-85.1,21.1c-18.1,21.1,0.8,35.1,0.8,56.3c0,42.2-114.5,42.2-132.6,21.1
                                            c-10.9-12.8-8.6-46.1-5.1-70.5c2.7-16.3,9.5-33.8,31.4-56.3l0,0c32.9-30.7,82.6-55.7,188.9-56.3l0,0c0.6,0,1.2,0,1.8,0
                                            c0.6,0,1.2,0,1.8,0l0,0c106.3,0.5,156,25.6,188.9,56.3l0,0C468.6,236.2,475.4,253.8,478.1,270.1L478.1,270.1z" />
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import intlTelInput from 'intl-tel-input';
import { useStopwatch } from 'vue-timer-hook';
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
    data() {
        return {
            showHangupButton: false,
            forCallingStatus: false,
            callConnected: false,
            hangupCallButton: false,
            callUuid: null,
            telNumberSecond: '',
            telNumber: '',
            countryCode: '1',
            numPadRows: [
                [1, 2, 3],
                [4, 5, 6],
                [7, 8, 9],
                ['*', 0, '#']
            ],
            watch: useStopwatch(false),
            callLogs: [],
            isOnHold: false,
            isRecording: false
        };
    },
    mounted() {
        document.querySelector('#outboundHangupSecond').addEventListener('click', this.hangupCallSecond);
        const phoneNumberInput = this.$refs.phoneNumberInput;
        const iti = intlTelInput(phoneNumberInput, {
            initialCountry: 'us',
            separateDialCode: true,
        });

        phoneNumberInput.addEventListener('countrychange', () => {
            this.countryCode = iti.getSelectedCountryData().dialCode;
        });
    },
    computed: {
        inputStyle() {
            return this.telNumber.length > 0 ? { border: 'unset' } : {};
        },
        formattedTime() {
            const { hours, minutes, seconds } = this.watch;
            return `${hours > 0 ? `${hours}:` : ''}${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
        },
        buttonClass() {
            return this.hangupCallButton ? 'btn btn-success btn-block flatbtn bg-red' : 'btn btn-success btn-block flatbtn';
        }
    },
    methods: {
        hangupCallSecond() {
            if (plivoBrowserSdk && plivoBrowserSdk.client) {
                plivoBrowserSdk.client.hangup();
            } else {
                console.error('Plivo SDK client is not available');
            }
            this.showHangupButton = false;
            this.telNumberSecond = '';
            this.forCallingStatus = '';
        },
        MakeCallD() {
            if (this.$refs.phoneNumberInput.checkValidity()) {
                this.showHangupButton = true;
                this.forCallingStatus = true;
                document.getElementById('toNumber').value = this.countryCode + this.telNumberSecond;
                document.getElementById('makecall').click();
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: "Phone number is required",
                });
            }
        },
        openModelClosedS() {
            this.$emit('openModelClosedFun');
        },
        handleCountryChange(e) {
            try {
                const selectedCountryData = e.target.intlTelInputElement.getSelectedCountryData();
                this.countryCode = selectedCountryData.dialCode;
                document.getElementById('mobile_code').value = `+${this.countryCode}`;
            } catch (error) {
                console.error("Error getting country code:", error);
            }
        },
        handleNumberClick(num) {
            this.telNumberSecond += num.toString();
        },
        backRemove() {
            if (this.telNumberSecond.length > 0) {
                this.telNumberSecond = this.telNumberSecond.slice(0, -1);
            }
        },
        async handleCall() {
            if (this.hangupCallButton) {
                this.hangupCall();
            } else {
                this.makeCall();
            }
        },
        async makeCall() {
            this.watch.reset();
            this.watch.pause();
            const phonenumber = this.countryCode + this.telNumber;
            this.forCallingStatus = true;
            this.hangupCallButton = true;

            const requestData = {
                user_id: localStorage.getItem('usertoken'),
                caller_id: localStorage.getItem('plivo_number'),
                destination_number: phonenumber,
                type: 'call'
            };
            const requestDa = {
                user_id: localStorage.getItem('usertoken'),
                sender: localStorage.getItem('plivo_number'),
                receiver: phonenumber,
                status: 'Outgoing',
                type: 'call'
            };
            // try {
            //     const response = await axios.post('/', requestData);
            //     this.savelogdatabase(requestDa);
            //     this.callUuid = response.data.call_uuid;
            //     this.callConnected = true;
            //     this.watch.start();
            // } catch (error) {
            //     console.error('API Error:', error);
            //     this.forCallingStatus = false;
            //     this.hangupCallButton = false;
            // }
        },
        async hangupCall() {
            this.watch.pause();
            this.callConnected = false;
            const elapsed = this.formattedTime;

            const requestData = {
                call_uuid: this.callUuid
            };

            try {
                await axios.post('/api/hangup-call', requestData);
                this.forCallingStatus = false;
                this.hangupCallButton = false;
                this.callUuid = null;
                this.telNumber = '';

                this.callLogs.push({
                    number: this.telNumber,
                    duration: elapsed
                });
                this.$emit('fetchCallLogsData');
            } catch (error) {
                console.error('Error hanging up call:', error);
            }
        },
        async toggleHold() {
            this.isOnHold = !this.isOnHold;
            try {
                // await axios.post('/api/toggle-hold', {
                //     callUuid: this.callUuid,
                //     hold: this.isOnHold
                // });
            } catch (error) {
                console.error('Error toggling hold:', error);
            }
        },
        async toggleRecord() {
            this.isRecording = !this.isRecording;
            try {
                // await axios.post('/api/toggle-record', {
                //     callUuid: this.callUuid,
                //     record: this.isRecording
                // });
            } catch (error) {
                console.error('Error toggling record:', error);
            }
        },
        savelogdatabase(logData) {
            axios.post('/api/saveLogs', logData)
                .then(logResponse => {
                    console.log('Log saved successfully:', logResponse.data);
                })
                .catch(logError => {
                    if (logError.response) {
                        console.error('Error saving log:', logError.response.data);
                    } else {
                        // console.error('Error saving log:', logError.message);
                    }
                });
        },

    }
};

</script>

<style scoped>
.iti__country-container {
    right: 164px !important;
}

.iti .iti__selected-dial-code {
    margin-left: 2px;
    font-size: 18px;
    margin-top: 1px;
}
</style>